import { Component, OnInit, ComponentRef, Inject } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from "ngx-modal-dialog";
import { Run, LiveStatsGameWrapper } from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-run-summary",
  templateUrl: "./run-summary.component.html",
  styleUrls: ["./run-summary.component.scss"]
})
export class RunSummaryComponent implements OnInit, IModalDialog {
  run: Run;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {}

  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<Run>>
  ) {
    this.run = options.data;
  }

  ngOnInit() {}
}
