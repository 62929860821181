import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Observable, combineLatest, ReplaySubject } from "rxjs";
import { switchMap, shareReplay } from "rxjs/operators";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

const cache: { [url: string]: Promise<string> } = {};

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
  animations: [
    trigger("fadeIn", [
      state("void", style({ opacity: 0 })),
      state("*", style({ opacity: 1 })),
      transition("* <=> *", animate("250ms ease")),
    ]),
  ],
})
export class ImageComponent implements OnChanges {
  @Input() src: string;
  @Input() fallbackSrc: string;
  @Input() alt: string;

  fallbackSrc$ = new ReplaySubject<string>(1);
  src$ = new ReplaySubject<string>(1);
  loadedSrc$: Observable<string>;

  constructor() {
    this.loadedSrc$ = combineLatest(this.src$, this.fallbackSrc$).pipe(
      switchMap(([src, fallbackSrc]) => {
        let loader: Promise<string>;

        const cacheKey = [src, fallbackSrc].join("|");

        if (cache[cacheKey] != null) {
          loader = cache[cacheKey];
        } else if (src && fallbackSrc) {
          loader = this.loadImage(src)
            .catch((e) => this.loadImage(fallbackSrc))
            .catch(() => null);
        } else if (fallbackSrc) {
          loader = this.loadImage(fallbackSrc).catch(() => null);
        } else if (src) {
          loader = this.loadImage(src).catch(() => null);
        } else {
          loader = Promise.resolve(null);
        }

        cache[cacheKey] = loader;

        return loader;
      }),
      shareReplay(1)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["src"] || changes["fallbackSrc"]) {
      this.src$.next(changes["src"].currentValue);
      this.fallbackSrc$.next(
        (changes["fallbackSrc"] && changes["fallbackSrc"].currentValue) || null
      );
    }
  }

  loadImage(src: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = (e) => {
        reject(e);
      };
      img.onload = () => {
        resolve(src);
      };
      img.src = src;
    });
  }
}
