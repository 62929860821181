import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnDestroy,
  Output
} from "@angular/core";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AppService } from "../app.service";
import { EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { v4 } from "uuid";
import { gptSlotRequestAnalytics } from "../../lib/gpt-analytics";
import {
  shouldUseHeaderBidding,
  queueForHeaderBidding
} from "@sidearmsports/gpt-header-bidding";

const targetting_criteria: { [key: string]: string } =
  (<any>window).targetting_criteria || {};

googletag.cmd.push(() => {
  googletag.pubads().disableInitialLoad();
});
@Component({
  selector: "app-game-dfp-ads",
  templateUrl: "./game-dfp-ads.component.html",
  styleUrls: ["./game-dfp-ads.component.scss"]
})
export class GameDfpAdsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  active: AdSize = null;
  _ad_timeout: number = null;
  _ads_to_record_impressions: number[] = [];

  @Input()
  sizeMapping: AdSize[] = [];

  @Input()
  locationName: string;

  @Input()
  locationType: "sidearm" | "dfp" = "dfp";

  @Output()
  rendered = new EventEmitter<{
    isEmpty: boolean;
    size: string | number[];
    slot: googletag.Slot;
  }>();

  @Input()
  additionalKvps: {} = {};

  constructor(
    private el: ElementRef,
    private app: AppService,
    private http: HttpClient
  ) { }

  public static newCorrelator() {
    googletag.cmd.push(() => {
      googletag.pubads().updateCorrelator();
    });
  }

  ngOnInit() {
    const iu =
      "livestats_dfp_tag" in window ? window["livestats_dfp_tag"] : null;

    this.app.windowSize$
      .pipe(
        takeUntil(this.destroy$),
        map(() => ({
          width: this.el.nativeElement.clientWidth,
          height: this.el.nativeElement.clientHeight
        }))
      )
      .subscribe(({ width }) => {
        const active = this.sizeMapping.find(
          sz => sz.width <= width || sz.force
        );

        if (this.active === active) {
          // already active, do nothing
        } else if (active) {
          if (this.locationType === "sidearm") {
            this.renderSidearmSpot(active, this.locationName);
          } else {
            this.renderDoubleclick(iu, active);
          }
        } else {
          this.clearExisting();
        }

        this.active = active;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getTargettingParams() {
    const page_template = "livestats";
    const sport_name = targetting_criteria.sport_name || "0";
    const sect = this.getCbsSectFrom(page_template, sport_name);

    return {
      site: targetting_criteria.site || "",
      page_template: page_template,
      ptype: page_template,
      sport_name: sport_name,
      sport_name_custom: targetting_criteria.sport_name_custom || "0",
      placement: this.locationName,
      sect: sect
    };
  }

  getCbsSectFrom(page_template: string, sport_name: string) {
    return (
      sidearmPageTemplateToCbsSect[page_template] ||
      sidearmSportNameToCbsSect[sport_name] ||
      "ot"
    );
  }

  queueAdImpression(ad_id: number) {
    if (this._ads_to_record_impressions.indexOf(ad_id) === -1) {
      this._ads_to_record_impressions.push(ad_id);
      this.recordAdImpressions();
    }
  }

  recordAdImpressions() {
    if (this._ad_timeout == null) {
      this._ad_timeout = <any>setTimeout(() => {
        if (this._ads_to_record_impressions.length > 0) {
          this.http
            .get("/services/ad_counter.aspx", {
              params: { ad_id: this._ads_to_record_impressions.join(",") }
            })
            .pipe(
              map((response: Response) => {
                return response.body;
              })
            )
            .subscribe();
          this._ads_to_record_impressions = [];
        }
      }, 5000);
    }
  }

  renderDoubleclick(iu: string, size: AdSize) {
    if (iu == null) {
      this.rendered.next({
        isEmpty: true,
        size: [0, 0],
        slot: null
      });
      return null;
    }

    const width = size.width;
    const sizes = (size.sizes || [size.height]).map(heightOrSize =>
      typeof heightOrSize === "number"
        ? ([width, heightOrSize] as [number, number])
        : heightOrSize
    );

    const instanceId = v4();
    const gptElId = `gpt-tag-${instanceId}`;

    this.clearExisting();

    const div = document.createElement("div");
    div.id = gptElId;
    this.el.nativeElement.appendChild(div);

    const kvps = Object.assign(
      {},
      this.getTargettingParams(),
      this.additionalKvps
    );

    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(iu, sizes, gptElId);

      Object.keys(kvps).forEach(k => {
        slot.setTargeting(k, kvps[k]);
      });
      slot.addService(googletag.pubads());
      googletag
        .pubads()
        .addEventListener(
          "slotRenderEnded",
          (slotRenderEvent: googletag.events.SlotRenderEndedEvent) => {
            if (slotRenderEvent.slot === slot) {
              this.rendered.next(slotRenderEvent);
            }
          }
        );

      const networkId = iu.split("/").filter(seg => seg)[0];

      const networkUsesAmazonHeaderBidding = shouldUseHeaderBidding(
        networkId,
        sizes.map(([sizeWidth, sizeHeight]) => ({
          width: sizeWidth,
          height: sizeHeight
        }))
      );
      if (networkUsesAmazonHeaderBidding) {
        queueForHeaderBidding({ slot, iu, sizes, id: gptElId });
      } else {
        googletag.pubads().refresh([slot]);
      }

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      googletag.display(gptElId);

      if (sizesContainsIabSizes(sizes)) {
        gptSlotRequestAnalytics({
          networkId: networkId,
          slot,
          element: (this.locationName || "unnamed").substring(0, 36),
          elinstance: instanceId
        });
      }
    });
  }

  renderSidearmSpot(size: AdSize, location: string) {
    const liveStatsPath: string[] = window.location.pathname.split("/");
    this.http
      .get("/api/ads", {
        params: {
          location: location ? location : `livestats-${size.width}x${size.height}`,
          global_sport_shortname: liveStatsPath[2].split(";")[0].toLowerCase()
        }
      })
      .forEach(resp => {
        const ads: {
          id: number;
          title: string;
          link: string;
          image: string;
          newWindow: boolean;
          html?: string;
        }[] = (<any>resp).ads;

        if (ads.length === 0) {
          this.rendered.next({
            isEmpty: true,
            size: [0, 0],
            slot: null
          });
          return;
        }
        const ad = ads[Math.floor(ads.length * Math.random())];

        const { link, title, image } = ad;

        const target = ad.newWindow ? "_blank" : "_parent";

        const html =
          ad.html ||
          `<a href="${link}" target="${target}"><img src="${image}" title="${title}"></a>`;

        this.clearExisting();
        const div = document.createElement("div");
        const iframe = document.createElement("iframe");
        iframe.marginHeight = "0";
        iframe.marginWidth = "0";
        iframe.frameBorder = "NO";
        iframe.scrolling = "NO";
        iframe.width = size.width.toString();
        iframe.height = size.height.toString();
        iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(html)}`;
        div.appendChild(iframe);
        this.el.nativeElement.appendChild(div);
        this.queueAdImpression(ad.id);
      });
  }
  clearExisting() {
    const el: HTMLElement = this.el.nativeElement;
    Array.from(el.children)
      .filter(el2 => el2.tagName.toLowerCase() === "div")
      .forEach(el2 => el2.remove());
  }
  onPause() {
    this.clearExisting();
  }
  onDestroy() { }
}

const iabSizes = [[320, 50], [300, 250], [728, 90], [970, 66], [970, 90]];

function sizesContainsIabSizes(sizes: [number, number][]) {
  return sizes.some(size =>
    iabSizes.some(iabSize => size[0] === iabSize[0] && size[1] === iabSize[1])
  );
}

const sidearmPageTemplateToCbsSect: { [x: string]: string } = {
  "home-page": "frontpage",
  "schedule-signingday": "signingday"
};

const sidearmSportNameToCbsSect: { [x: string]: string } = {
  cross: "cxc",
  track: "ctrack",
  baseball: "mbasebl",
  mbball: "mbaskbl",
  football: "mfootbl",
  mgolf: "mgolf",
  mhockey: "mhockey",
  msoc: "msoccer",
  mgym: "mgym",
  mlax: "mlacros",
  mswim: "mswim",
  mten: "mtennis",
  mtrack: "mtrack",
  mvball: "mvolley",
  wrestling: "mwrestl",
  wbball: "wbaskbl",
  fhockey: "wfieldh",
  wgolf: "wgolf",
  wgym: "wgym",
  whockey: "whockey",
  wlax: "wlacros",
  wsoc: "wsoccer",
  softball: "wsoftbl",
  wswim: "wswim",
  wten: "wtennis",
  wtrack: "wtrack",
  wvball: "wvolley",
  wwpolo: "wwpolo"
};

interface AdSize {
  width: number;
  force?: boolean;
  height?: number;
  sizes?: ([number, number] | number)[];
}
