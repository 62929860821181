import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewContainerRef
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsPlayer,
  LiveStatsGameTeam,
  TeamPascalKey,
  LiveStatsFootballDrives
} from "../live-stats-service.service";
import { groupBy, entries } from "lodash";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { ModalDialogService } from "ngx-modal-dialog";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";

@Component({
  selector: "app-game-plays",
  templateUrl: "./game-plays.component.html",
  styleUrls: ["./game-plays.component.scss"],
  animations: [
    trigger("slideDown", [
      state("void", style({ height: 0 })),
      state("*", style({ height: "*" })),
      transition("* <=> *", animate("250ms ease"))
    ])
  ]
})
export class GamePlaysComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];
  private _drives: LiveStatsFootballDrives[] = [];
  private _scoringDrives = [];

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays.slice().reverse();
    if (value.type === "FootballGame") {
      this._drives = value.footballGame.drives.slice().reverse();
    }
  }
  get game() {
    return this._game;
  }

  @Input()
  selectedTeam: TeamPascalKey;
  @Input()
  selectedPlayer: string;
  @Input()
  type: string;
  @Input()
  action: string;
  @Input()
  count: number;
  @Input()
  layoutType: string;

  visiblePeriod = null;

  get filteredPlays(): LiveStatsPlay[] {
    return this._plays.filter(p => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (this.type) {
        if (this.type === "Scoring") {
          if (p.score == null) {
            return false;
          }
        } else if (p.type !== this.type) {
          return false;
        }
      }
      if (this.action && p.action !== this.action) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player]
            .concat(p.involvedPlayers)
            .find(
              pl =>
                pl.team === this.selectedTeam &&
                pl.uniformNumber === this.selectedPlayer
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get scoringPlays(): LiveStatsPlay[] {
    return this._plays
      .filter(p => {
        if (p.score === null) {
          return false;
        }
        return true;
      });
  }

  get scoringPlaysFootball(): LiveStatsPlay[] {
    return this.game.footballGame.scoringSummary;
  }

  get filteredPlaysByPeriod(): { period: number; plays: LiveStatsPlay[] }[] {
    return entries(groupBy(this.filteredPlays, p => p.period))
      .map(([periodString, plays]) => {
        return {
          period: Number(periodString),
          plays
        };
      })
      .reverse();
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(g => (this.game = g));
  }

  isPlayActive = false;
  isTrendActive = false;
  public runPlays = [];
  public gameRun;
  selectedPlays = 0;

  toggleRun(event, play: LiveStatsPlay) {
    if (this.selectedPlays <= 2) {
      this.isTrendActive = false;
      if (this.runPlays.includes(play)) {
        // lower selectedPlays counter
        this.selectedPlays = this.selectedPlays - 1;
        for (const i of this.runPlays) {
          // Remove play from runPlay array
          if (i.id === play.id) {
            const index = this.runPlays.indexOf(i);
            this.runPlays.splice(index, 1);
          }
        }
      } else {
        // Add selected play to runPlay array
        this.runPlays.push(play);
        this.selectedPlays = this.selectedPlays + 1;
      }
    }
    if (this.selectedPlays === 2) {
      // Show trend if two plays are selected
      this.isTrendActive = true;
      this.gameRun = this.game.calculateRun(this.runPlays);
    }
    if (this.selectedPlays > 2) {
      // If a 3rd play is selected reset trend
      this.isTrendActive = false;
      this.runPlays = [];
      this.runPlays.push(play);
      this.selectedPlays = 1;
    }
  }

  playId(play: LiveStatsPlay) {
    return play.id;
  }

  periodNumber(obj: { period: number }) {
    return obj.period;
  }

  trackBy(index) {
    return index;
  }

  get showAllPeriods(): boolean {
    return !!(this.type || this.action || this.selectedTeam);
  }

  showPeriodPlays(period: number) {
    if (this.showAllPeriods) {
      return true;
    }
    return this.visiblePeriod == null
      ? period === this.game.game.period
      : this.visiblePeriod === period;
  }

  toggleTab(period: number) {
    if (this.visiblePeriod === period) {
      this.visiblePeriod = -1;
    } else {
      this.visiblePeriod = period;
    }
  }

  showPlayerStats(player: LiveStatsPlayer, team: LiveStatsGameTeam) {
    this.modalService.openDialog(this.viewRef, {
      title: `${team.name} - ${player.firstName} ${player.lastName} Stats`,
      childComponent: GamePlayerModalComponent,
      data: player
    });
  }

  ngOnInit() {}
}
