import { Component, OnInit, Input } from "@angular/core";
import {
  LiveStatsPlay,
  LiveStatsGameWrapper,
  Run,
} from "../live-stats-service.service";

@Component({
  selector: "app-game-trend",
  templateUrl: "./game-trend.component.html",
  styleUrls: ["./game-trend.component.scss"],
})
export class GameTrendComponent implements OnInit {
  @Input() run: Run;
  @Input() game: LiveStatsGameWrapper;

  colorScheme: Object;

  constructor() {}

  view: any[] = [320, 180];
  showXAxis = false;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = "Teams";
  showXAxisLabel = false;
  xAxisLabel = "Time";
  showYAxisLabel = false;
  yAxisLabel = "Points";
  tooltipDisabled = true;
  autoScale = true;

  get scoringPlays(): LiveStatsPlay[] {
    return this.run.allPlaysInTime
      .filter((p) => {
        if (p.score === null) {
          return false;
        }
        return true;
      })
      .reverse();
  }

  get runResults(): Series {
    return {
      name: "Run Score",
      results: [this.game.homeTeam, this.game.visitingTeam].map((t) => ({
        name: t.name,
        series: this.scoringPlays
          .map((p, i) => ({
            name: i.toString(),
            value:
              t === this.game.homeTeam
                ? p.score.homeTeam
                : p.score.visitingTeam,
          }))
          .reverse(),
      })),
    };
  }

  ngOnInit() {
    this.colorScheme = {
      domain: [
        this.game.game.homeTeam.color,
        this.game.game.visitingTeam.color,
        "#d9d9db",
      ],
    };
  }
}

interface Series {
  name: string;
  results: { name: string; series: Values[] }[];
}
interface Values {
  name: string;
  value: number;
}
