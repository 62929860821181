import {
  Component,
  EventEmitter,
  Output,
  AfterViewInit,
  QueryList,
  ContentChildren,
  ViewChild,
  ElementRef,
  HostBinding,
  Input,
} from "@angular/core";
import { Tab } from "../tab.interface";
import { TabComponent } from "../tab/tab.component";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { LiveStatsService } from "../live-stats-service.service";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements AfterViewInit {
  @Input()
  @HostBinding("attr.tabStyle")
  tabStyle: "tabs" | "segment" = "tabs";
  @Output()
  selected = new EventEmitter();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @ViewChild("wrapper", { static: false }) wrapperEl: ElementRef;
  @ViewChild("ul", { static: false }) ulEl: ElementRef;

  @Input()
  tabParamName: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.selected.subscribe((event) => {
      if (this.tabParamName) {
        this.router.navigate(
          [
            ".",
            Object.assign(
              {},
              LiveStatsService.without(this.route.snapshot.paramMap["params"], [
                "school",
                "sport",
              ]),
              {
                [this.tabParamName]: event.selectedTab.tabID,
              }
            ),
          ],
          {
            relativeTo: this.route,
          }
        );
      }
    });
  }

  ngAfterViewInit(): void {
    this.route.params
      .pipe(map((d) => d[this.tabParamName]))
      .subscribe((value) => {
        const tab = this.tabs.find((t) => t.tabID === value);
        if (tab) {
          this.selectTab(tab);
        } else {
          this.selectTab(this.tabs.first);
        }
      });

    this.tabs.changes.subscribe(() => {
      this.selectTab(this.tabs.first);
    });
  }

  selectTab(tab: Tab) {
    this.tabs.map((innertab) => {
      innertab.selected = false;
    });
    if (tab != null) {
      tab.selected = true;
    }
    this.selected.emit({ selectedTab: tab });
  }
}
