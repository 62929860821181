import { Injectable, NgZone } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { isPlainObject, isArray, camelCase, padStart } from "lodash";
import {
  map,
  retryWhen,
  delay,
  switchMap,
  shareReplay,
  scan,
  first,
  concat,
  distinctUntilChanged
} from "rxjs/operators";

import { DiffPatcher } from "jsondiffpatch";

import * as tinycolor from "tinycolor2";
import { isDevMode } from "@angular/core";
import {
  sidearmSignalr,
  SidearmSignalrConnection
} from "./util/sidearm-realtime";
import { environment } from './../environments/environment';

const gameTypeStatArrayOrder: { [gameType: string]: string[] } = {
  BaseballSoftballGame: [
    "pitching",
    "pitchingSituation",
    "pitchingSeason",
    "batting",
    "battingSeason",
    "fielding"
  ]
};

@Injectable()
export class LiveStatsService {
  private differ: DiffPatcher;

  constructor(private http: HttpClient, private zone: NgZone) {
    this.differ = new DiffPatcher({
      objectHash: function (obj, i) {
        if (obj instanceof LiveStatsStatsGrid) {
          return i;
        }

        if (
          obj.values &&
          Object.prototype.toString.call(obj.values) === "[object Array]"
        ) {
          return obj.values.slice(0, 2).join(",");
        }

        const hash =
          obj.id ||
          obj.key ||
          (obj.player && `${obj.player.firstName} ${obj.player.lastName}`) ||
          ("firstName" in obj && "lastName" in obj
            ? `${obj.firstName} ${obj.lastName}`
            : null);

        if (hash == null) {
          console.error("no hash available for object", obj);
          return Math.random();
        }

        return hash;
      },
      arrays: {
        includeValueOnMove: true
      }
    });
  }

  static without(obj: {}, keys: string[]) {
    return Object.keys(obj)
      .filter(k => !keys.includes(k))
      .reduce((prev, key) => {
        prev[key] = obj[key];
        return prev;
      }, {});
  }

  liveStatsConfigFor(
    schoolSport$: Observable<{
      schoolUrlName: string;
      school: string;
      sport: string;
    }>
  ) {
    return schoolSport$.pipe(
      switchMap(ss =>
        this.http
          .get(`${environment.sidearmstats_base_url}/${ss.school}/${ss.sport}/game.json`, {
            params: { detail: "game" }
          })
          .pipe(
            retryWhen(err$ =>
              err$.pipe(
                map(err => {
                  if (
                    err instanceof HttpErrorResponse &&
                    (<HttpErrorResponse>err).status === 404
                  ) {
                    // throw here to give up
                    throw new Error(`${err.status} ${err.statusText}`);
                  }
                }),
                delay(2000)
              )
            ),
            map((game: any) => ({
              hostname: mapToCamel(game).game.clientHostname as string,
              sport: ss.sport,
              schoolUrlName: ss.schoolUrlName,
              school: ss.school
            }))
          )
      ),
      shareReplay()
    );
  }

  observeGame(school: string, sport: string): Observable<any> {
    return new Observable(observer => {
      if (environment.disable_realtime) {
        return;
      }
      let socket: SidearmSignalrConnection;
      let isDestroying = false;
      sidearmSignalr(`${school}/${sport}`, this.zone).then(s => {
        socket = s;
        [
          ["game", "Game"],
          ["plays", "Plays"],
          ["leaders", "Leaders"],
          ["stats", "Stats"],
          ["seasonstats", "SeasonStats"],
          ["footballgame", "FootballGame"]
        ].forEach(([topic, property]) => {
          s.listen(topic, value => {
            const nextValue = { [property]: value };
            observer.next(nextValue);
          });
        });
        if (isDestroying && socket) {
          socket.destroy();
          socket = null;
        }
      });
      return () => {
        isDestroying = true;
        if (socket) {
          socket.destroy();
          socket = null;
        }
      };
    });
  }

  liveGameStream(school: string, sport: string) {
    return this.http
      .get(`${environment.sidearmstats_base_url}/${school}/${sport}/game.json`, {
        params: { detail: "full" }
      })
      .pipe(
        first(),
        concat(this.observeGame(school, sport)),
        scan((agg, v) => {
          const newValue = Object.assign(agg, v);
          return newValue;
        }, {})
      );
  }

  liveStatsFor(
    schoolSport$: Observable<{
      school: string;
      sport: string;
      hostname: string;
      game?: string;
    }>
  ): Observable<LiveStatsGameWrapper> {
    return schoolSport$.pipe(
      switchMap(ss =>
        (ss.game == null
          ? this.liveGameStream(ss.school, ss.sport)
          : this.http.jsonp(
            `//${environment.force_oas_hostname ? environment.force_oas_hostname : ss.hostname}/api/livestats?game_id=${ss.game}&detail=full`,
            "callback"
          )
        ).pipe(
          retryWhen(err => err.pipe(delay(2000))),
          scan(
            (acc, v, i) => {
              const newValue = mapToCamel(v);

              const newObject = new LiveStatsGameWrapper(newValue, v);
              if (acc == null) {
                return newObject;
              }
              const diff = this.differ.diff(acc, newObject);

              if (diff == null) {
                return acc;
              }

              let returnValue: LiveStatsGameWrapper;
              try {
                returnValue = this.differ.patch(acc, diff);
              } catch (err) {
                console.error(err);
                returnValue = newObject;
              }

              returnValue.refreshId++;

              return returnValue;
            },
            null as LiveStatsGameWrapper
          ),
          distinctUntilChanged((a, b) => a.refreshId !== b.refreshId)
        )
      )
    );
  }

  archivedGamesFor(
    schoolSport$: Observable<{
      schoolUrlName: string;
      school: string;
      sport: string;
      hostname: string;
    }>
  ): Observable<ArchivedGameReference[]> {
    return schoolSport$.pipe(
      distinctUntilChanged(),
      switchMap(ssh =>
        this.http
          .jsonp(
            `${location.protocol}//${environment.force_oas_hostname ? environment.force_oas_hostname : ssh.hostname
            }/services/cumestats.ashx/games?global_sport_shortname=${ssh.sport
            }`,
            "callback"
          )
          .pipe(
            map((games: any[]) =>
              games.map(
                game =>
                  Object.assign(mapToCamel(game), {
                    school: ssh.schoolUrlName,
                    sport: ssh.sport
                  }) as ArchivedGameReference
              )
            )
          )
      ),
      shareReplay()
    );
  }
}

export interface ArchivedGameReference {
  gameId: string;
  date: string;
  opponentTeam: string;
  thisTeamScore: string;
  opponentTeamScore: string;
  thisTeamWon: boolean;
  school: string;
  sport: string;
}

function mapToCamel(object: any): any {
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((agg, key) => {
      agg[makeCamelCase(key)] = mapToCamel(object[key]);
      return agg;
    }, {});
  }
  if (isArray(object)) {
    return object.map(v => mapToCamel(v));
  }
  return object;
}

function makeCamelCase(str: string): string {
  const dict = { "+": "Plus", "-": "Minus" };
  const re = new RegExp(`[${Object.keys(dict).join("")}]`, "g");
  str = str.replace(re, m => dict[m]);
  return camelCase(str);
}

export type TeamCamelKey = "visitingTeam" | "homeTeam";
export type TeamPascalKey = "HomeTeam" | "VisitingTeam";

export class LiveStatsGameWrapper implements ILiveStatsGameWrapper {
  refreshId = 0;

  game: LiveStatsGame;
  leaders: LiveStatsLeaders;
  stats: { [team in TeamCamelKey]: LiveStatsStats };
  seasonStats: { [team in TeamCamelKey]: LiveStatsSeasonStats };
  plays: LiveStatsPlay[];
  footballGame: LiveStatsFootballGameDetails;
  get source() {
    return this.game && this.game.source;
  }
  get type() {
    return this.game && this.game.type;
  }

  get state(): "pre" | "mid" | "post" {
    return !this.game.hasStarted
      ? "pre"
      : this.game.isComplete
        ? "post"
        : "mid";
  }

  get homeTeam() {
    return this.game.homeTeam;
  }

  get visitingTeam() {
    return this.game.visitingTeam;
  }

  get homeTeamPlayers() {
    return this.stats.homeTeam.players;
  }

  get visitingTeamPlayers() {
    return this.stats.visitingTeam.players;
  }

  constructor(input: any, original: any) {
    this.game = input.game;

    this.game.notes = this.game.notes.filter(n => n !== "Game: livegame");

    [this.game.homeTeam, this.game.visitingTeam].forEach(team => {
      team.textColor = tinycolor
        .mostReadable(team.color, [team.textColor], {
          includeFallbackColors: true,
          level: "AA"
        })
        .toHexString();
    });

    this.leaders = Object.assign(
      {
        categories: Object.keys(original.Leaders.HomeTeam).map(key => ({
          key: makeCamelCase(key),
          name: key
        })),
        categoriesByName: Object.keys(original.Leaders.HomeTeam).reduce(
          (agg, key) => {
            agg[makeCamelCase(key)] = {
              name: key,
              visitingTeam: input.leaders.visitingTeam,
              homeTeam: input.leaders.homeTeam
            };
            return agg;
          },
          {}
        )
      },
      input.leaders
    );
    this.stats = {
      homeTeam: new LiveStatsStats(input.stats.homeTeam),
      visitingTeam: new LiveStatsStats(input.stats.visitingTeam)
    };
    this.seasonStats = {
      homeTeam: (input.seasonStats && input.seasonStats.homeTeam) == null ? null : new LiveStatsSeasonStats(input.seasonStats.homeTeam),
      visitingTeam: (input.seasonStats && input.seasonStats.visitingTeam) == null ? null : new LiveStatsSeasonStats(input.seasonStats.visitingTeam)
    };
    this.plays = input.plays;

    this.footballGame =
      input.footballGame == null
        ? null
        : new LiveStatsFootballGameDetails(input.footballGame, input.plays);
  }

  _defaultExcludedPlayerStatsKeys = [
    "uni",
    "name",
    "position",
    "currentlyInGame"
  ];

  getPlayerStats(
    player: LiveStatsPlayer,
    mask?: { [group: string]: string[] }
  ): { [group: string]: LiveStatsPlayerStats } {
    const teamStats = this.stats[teamPascalToCamel(player.team)];
    const playerGroups = teamStats.playerGroups;
    return Object.keys(playerGroups)
      .filter(k => mask == null || mask[k] != null)
      .map(k => {
        const group = playerGroups[k];
        const personId_index = group.keys.indexOf("personId");
        let playerStats = personId_index !== -1 && player.personId !== "" ? group.players.find(p => p.values[personId_index] === player.personId) : null;

        if (playerStats == null) {
          const uniIndex = group.keys.indexOf("uni");
          playerStats = group.players.find(
            p => p.values[uniIndex] === player.uniformNumber
          );
        }
        if (playerStats == null) {
          return null;
        }
        const groupMask = mask && mask[k];

        const indexes =
          groupMask == null
            ? group.keys
              .map((_, i) => i)
              .filter(
                i =>
                  !this._defaultExcludedPlayerStatsKeys.includes(
                    group.keys[i]
                  )
              )
            : groupMask.map(v => group.keys.indexOf(v));

        return {
          group: k,
          title: group.title,
          keys: indexes.map(i => group.keys[i]),
          abbreviations: indexes.map(i => group.abbreviations[i]),
          descriptions: indexes.map(i => group.descriptions[i]),
          values: indexes.map(i => playerStats.values[i]),
          eqZero: indexes.map(i => playerStats.eqZero[i])
        };
      })
      .filter(s => s != null && s.eqZero.filter(eqZero => !eqZero).length > 0)
      .reduce((agg, next) => {
        agg[next.group] = {
          title: next.title,
          keys: next.keys,
          descriptions: next.descriptions,
          abbreviations: next.abbreviations,
          values: next.values,
          eqZero: next.eqZero
        };
        return agg;
      }, {});
  }

  getPlayerStatsArray(
    player: LiveStatsPlayer,
    mask?: { [group: string]: string[] }
  ): { group: string; title: string; stats: LiveStatsPlayerStats }[] {
    const stats = this.getPlayerStats(player, mask);
    const order = gameTypeStatArrayOrder[this.game.type];
    return Object.keys(stats)
      .sort((a, b) => {
        if (order == null) {
          return 0;
        }
        const aIndex = order.indexOf(a);
        const bIndex = order.indexOf(b);
        return aIndex - bIndex;
      })
      .map(group => ({
        group,
        title: stats[group].title,
        stats: stats[group]
      }));
  }

  getPlayerSeasonStats(
    player: LiveStatsPlayer,
    mask?: { [group: string]: string[] }
  ): { [group: string]: LiveStatsPlayerStats } {
    const teamSeasonStats = this.seasonStats[teamPascalToCamel(player.team)];
    const playerGroups = teamSeasonStats.playerGroups;
    return Object.keys(playerGroups)
      .filter(k => mask == null || mask[k] != null)
      .map(k => {
        const group = playerGroups[k];
        const personId_index = group.keys.indexOf("personId");
        let playerStats = personId_index !== -1 && player.personId !== "" ? group.players.find(p => p.values[personId_index] === player.personId) : null;

        if (playerStats == null) {
          const uniIndex = group.keys.indexOf("uni");
          playerStats = group.players.find(
            p => p.values[uniIndex] === player.uniformNumber
          );
        }
        if (playerStats == null) {
          return null;
        }
        const groupMask = mask && mask[k];

        const indexes =
          groupMask == null
            ? group.keys
              .map((_, i) => i)
              .filter(
                i =>
                  !this._defaultExcludedPlayerStatsKeys.includes(
                    group.keys[i]
                  )
              )
            : groupMask.map(v => group.keys.indexOf(v));

        return {
          group: k,
          title: group.title,
          keys: indexes.map(i => group.keys[i]),
          abbreviations: indexes.map(i => group.abbreviations[i]),
          descriptions: indexes.map(i => group.descriptions[i]),
          values: indexes.map(i => playerStats.values[i]),
          eqZero: indexes.map(i => playerStats.eqZero[i])
        };
      })
      .filter(s => s != null && s.eqZero.filter(eqZero => !eqZero).length > 0)
      .reduce((agg, next) => {
        agg[next.group] = {
          title: next.title,
          keys: next.keys,
          descriptions: next.descriptions,
          abbreviations: next.abbreviations,
          values: next.values,
          eqZero: next.eqZero
        };
        return agg;
      }, {});
  }

  getPlayerSeasonStatsArray(
    player: LiveStatsPlayer,
    mask?: { [group: string]: string[] }
  ): { group: string; title: string; stats: LiveStatsPlayerStats }[] {
    const stats = this.getPlayerSeasonStats(player, mask);
    const order = gameTypeStatArrayOrder[this.game.type];
    return Object.keys(stats)
      .sort((a, b) => {
        if (order == null) {
          return 0;
        }
        const aIndex = order.indexOf(a);
        const bIndex = order.indexOf(b);
        return aIndex - bIndex;
      })
      .map(group => ({
        group,
        title: stats[group].title,
        stats: stats[group]
      }));
  }

  findPlayerFromIndividiualStats(player: LiveStatsPlayerStatsGridPlayer, keys: string[] = []) {
    const personId_index = keys.indexOf("personId");
    const player_personId = personId_index !== -1 && player.values[personId_index] !== "" ? player.values[personId_index] : null;
    const foundTeamStats = [this.stats.visitingTeam, this.stats.homeTeam].find(
      thisTeamStats => {
        return (
          Object.keys(thisTeamStats.playerGroups).find(groupKey => {
            const playersInGroup = thisTeamStats.playerGroups[groupKey];
            if (player_personId !== null) {
              const player_group_personId_index = playersInGroup.keys.indexOf("personId");
              if (player_group_personId_index) {
                return playersInGroup.players.find(p => {
                  return p.values[player_group_personId_index] === player_personId
                }) != null;
              }
            }
            return (
              playersInGroup.players.find(
                p =>
                  p.values[0] === player.values[0] &&
                  p.values[1] === player.values[1]
              ) != null
            );
          }) != null
        );
      }
    );

    if (foundTeamStats == null) {
      console.warn("Could not find team for player");
      return;
    }

    let foundPlayer: LiveStatsPlayer = null;
    if (player_personId !== null) {
      foundPlayer = foundTeamStats.players.find(
        (p) => p.personId === player_personId
      );
    }
    if (!foundPlayer) {
      foundPlayer = foundTeamStats.players.find(
        p => p.uniformNumber === player.values[0]
      );
    }

    if (foundPlayer == null) {
      console.warn("Found team but could not find player");
      return;
    }

    const foundTeam =
      foundTeamStats === this.stats.visitingTeam
        ? this.visitingTeam
        : this.homeTeam;

    return {
      player: foundPlayer,
      team: foundTeam
    };
  }

  getTeam(team: TeamPascalKey | TeamCamelKey) {
    if (team === "HomeTeam") {
      return this.game.homeTeam;
    }
    if (team === "VisitingTeam") {
      return this.game.visitingTeam;
    }
    if (team === "homeTeam") {
      return this.game.homeTeam;
    }
    if (team === "visitingTeam") {
      return this.game.visitingTeam;
    }
    throw new Error("Unknown team requested: " + team);
  }

  getTeamStats(team: TeamPascalKey) {
    if (team === "HomeTeam") {
      return this.stats.homeTeam;
    }
    if (team === "VisitingTeam") {
      return this.stats.visitingTeam;
    }
    throw new Error("Unknown team requested: " + team);
  }

  private isPlay(item: any): item is LiveStatsPlay {
    return item.narrative != null;
  }

  calculateTimeFromStart(play?: LiveStatsPlay);
  calculateTimeFromStart(period: number, clockSeconds: number);
  calculateTimeFromStart(
    playPeriod?: number | LiveStatsPlay,
    clockSeconds?: number
  ) {
    if (playPeriod == null && clockSeconds == null) {
      return this.calculateTimeFromStart(
        this.game.period,
        this.game.clockSeconds
      );
    }

    if (this.isPlay(playPeriod)) {
      return this.calculateTimeFromStart(
        playPeriod.period,
        playPeriod.clockSeconds
      );
    }

    if (this.game.rules.clockDirection === "None") {
      return playPeriod;
    }

    if (this.game.rules.clockSegmentation === "Continuous") {
      if (this.game.rules.clockDirection === "Up") {
        return clockSeconds;
      } else {
        return this.expectedGameLength() - clockSeconds;
      }
    }

    return this.calculatePeriodEndTime(playPeriod) - clockSeconds;
  }

  calculatePeriodEndTime(period) {
    const periodsRegulation = this.game.periodsRegulation;
    const periodMinutes = this.game.rules.periodMinutes;

    let endTime = Math.min(period, periodsRegulation) * periodMinutes * 60;

    if (period > periodsRegulation) {
      const otMinutes = this.game.rules.otMinutes;
      endTime += (period - periodsRegulation) * (otMinutes * 60);
    }

    return endTime;
  }

  expectedGameLength() {
    if (this.game.rules.clockDirection === "None") {
      return this.game.periodsRegulation;
    }
    let expected =
      this.game.rules.periodMinutes * 60 * this.game.periodsRegulation;
    if (this.game.period > this.game.periodsRegulation) {
      expected +=
        (this.game.period - this.game.periodsRegulation) *
        this.game.rules.otMinutes *
        60;
    }
    return expected;
  }

  calculateRunBetween(playStart: LiveStatsPlay, playEnd?: LiveStatsPlay) {
    const startIndex = this.plays.findIndex(p => p.id === playStart.id);
    if (playEnd == null) {
      return this.calculateRun(this.plays.slice(startIndex + 1));
    }
    const endIndex = this.plays.findIndex(p => p.id === playEnd.id);
    return this.calculateRun(
      withLastScore(this.plays).slice(startIndex + 1, endIndex)
    );
  }

  calculateRun(plays: LiveStatsPlay[]): Run {
    if (plays.length === 0) {
      return null;
    }
    let firstId = plays[0].id;
    let lastId = plays.slice(-1)[0].id;

    if (this.plays.indexOf(plays[0]) > this.plays.indexOf(plays.slice(-1)[0])) {
      firstId = plays.slice(-1)[0].id;
      lastId = plays[0].id;
    } else {
      firstId = plays[0].id;
      lastId = plays.slice(-1)[0].id;
    }

    const withScores = withLastScore(this.plays);

    const firstPlay = withScores.find(p => p.id === firstId);
    const lastPlay = withScores.find(p => p.id === lastId);
    const previousPlay = withScores[withScores.indexOf(firstPlay) - 1];

    const startTime = previousPlay
      ? this.calculateTimeFromStart(
        previousPlay.period,
        previousPlay.clockSeconds
      )
      : 0;

    let start;
    if (firstPlay.type.toUpperCase() === "GOAL" || previousPlay === undefined) {
      start = {
        period: firstPlay.period,
        clockSeconds: firstPlay.clockSeconds
      };
    } else {
      start = {
        period: previousPlay.period,
        clockSeconds: previousPlay.clockSeconds
      };
    }

    const end = { period: lastPlay.period, clockSeconds: lastPlay.clockSeconds };

    const tdiff =
      this.calculateTimeFromStart(lastPlay.period, lastPlay.clockSeconds) - startTime;

    const hdiff =
      lastPlay.score.homeTeam -
      (previousPlay && firstPlay.score.homeTeam >= previousPlay.score.homeTeam
        ? previousPlay.score.homeTeam
        : 0);

    const vdiff =
      lastPlay.score.visitingTeam -
      (previousPlay &&
        firstPlay.score.visitingTeam >= previousPlay.score.visitingTeam
        ? previousPlay.score.visitingTeam
        : 0);

    const runTeam =
      vdiff === hdiff
        ? null
        : vdiff > hdiff
          ? this.game.visitingTeam
          : this.game.homeTeam;

    const teamStats: {
      [team in TeamCamelKey]: {
        fg: { GOOD: number; MISS: number };
        three: { GOOD: number; MISS: number };
        ft: { GOOD: number; MISS: number };
        kill: number;
        goal: number;
        block: number;
        turnover: number;
        steal: number;
      }
    } = <any>["homeTeam", "visitingTeam"].reduce((agg, team) => {
      agg[team] = {};
      ["fg", "three", "ft"].forEach(shot => {
        agg[team][shot] = {};
        ["GOOD", "MISS"].forEach(result => {
          agg[team][shot][result] = 0;
        });
      });
      ["block", "turnover", "steal"].forEach(stat => {
        agg[team][stat] = 0;
      });
      return agg;
    }, {});

    const playerPoints: {
      [team in TeamCamelKey]: { [name: string]: number }
    } = {
      visitingTeam: {},
      homeTeam: {}
    };

    const allPlaysInTime = this.plays.slice(
      this.plays.findIndex(p => p.id === firstId),
      this.plays.findIndex(p => p.id === lastId) + 1
    );

    let scoringPlays = [];

    scoringPlays = allPlaysInTime.filter(p => {
      if (p.score !== null) {
        return true;
      }
    });

    allPlaysInTime.forEach((play, i) => {

      // Skip timeouts
      if (play.team == null) {
        return;
      }

      const playTeam = makeCamelCase(play.team);

      switch (play.type.toUpperCase()) {
        case "JUMPER":
        case "LAYUP":
        case "TIPIN":
        case "DUNK":
          teamStats[playTeam].fg[play.action]++;
          if (play.action === "GOOD") {
            const playerName =
              play.player.firstName + " " + play.player.lastName;
            playerPoints[playTeam][playerName] =
              (playerPoints[playTeam][playerName] || 0) + 2;
          }
          break;
        case "3PTR":
          teamStats[playTeam].fg[play.action]++;
          teamStats[playTeam].three[play.action]++;
          if (play.action === "GOOD") {
            const playerName =
              play.player.firstName + " " + play.player.lastName;
            playerPoints[playTeam][playerName] =
              (playerPoints[playTeam][playerName] || 0) + 3;
          }
          break;
        case "FT":
          teamStats[playTeam].ft[play.action]++;
          if (play.action === "GOOD") {
            const playerName =
              play.player.firstName + " " + play.player.lastName;
            playerPoints[playTeam][playerName] =
              (playerPoints[playTeam][playerName] || 0) + 1;
          }
          break;
        case "KILL":
          {
            const playerName =
              play.player.firstName + " " + play.player.lastName;
            playerPoints[playTeam][playerName] =
              (playerPoints[playTeam][playerName] || 0) + 1;
          }
          break;
        case "GOAL":
          {
            const playerName =
              play.player.firstName + " " + play.player.lastName;
            playerPoints[playTeam][playerName] =
              (playerPoints[playTeam][playerName] || 0) + 1;
          }
          break;
      }
      switch (play.action) {
        case "BLOCK":
          teamStats[playTeam].block++;
          break;
        case "TURNOVER":
          teamStats[playTeam].turnover++;
          break;
        case "STEAL":
          teamStats[playTeam].steal++;
          break;
      }
    });
    return {
      runTeam: runTeam,
      timeDiff: this.formatClock({ clockSeconds: tdiff }),
      start,
      end,
      homeTeam: {
        points: hdiff,
        teamStats: teamStats.homeTeam,
        playerPoints: Object.keys(playerPoints.homeTeam)
          .map(name => ({
            name: name,
            points: playerPoints.homeTeam[name]
          }))
          .sort((a, b) => b.points - a.points)
      },
      visitingTeam: {
        points: vdiff,
        teamStats: teamStats.visitingTeam,
        playerPoints: Object.keys(playerPoints.visitingTeam)
          .map(name => ({
            name: name,
            points: playerPoints.visitingTeam[name]
          }))
          .sort((a, b) => b.points - a.points)
      },
      plays: plays,
      allPlaysInTime: allPlaysInTime,
      scoringPlays: scoringPlays
    };
  }

  public formatPointInTime(
    point: PointInTime,
    options?: { ordinal?: boolean }
  ) {
    return `${this.formatClock({
      clockSeconds: point.clockSeconds
    })} ${this.formatPeriod({
      period: point.period,
      ordinal: options.ordinal
    })}`;
  }

  public formatPeriod(
    options: {
      period?: number;
      ordinal?: boolean;
      includePeriodName?: boolean;
      inning?: number;
    } = {}
  ) {
    let { ordinal, includePeriodName, period } = options;
    const { inning } = options;

    if (ordinal == null) {
      ordinal = false;
    }
    if (includePeriodName == null) {
      includePeriodName = false;
    }
    if (period == null) {
      period = this.game.period;
    }

    const isOT = period > this.game.periodsRegulation;

    const displayPeriod =
      isOT && this.game.rules.showExtraPeriodsAsOt
        ? period - this.game.periodsRegulation
        : period;

    const numberAsString = ordinal ? toOrdinal(displayPeriod) : displayPeriod;

    const periodName =
      isOT && this.game.rules.showExtraPeriodsAsOt
        ? "OT"
        : this.game.rules.periodName;

    if (isOT) {
      return inning != null
        ? `${inning % 1 === 0 ? "Top" : "Bottom"} ${numberAsString}`
        : periodName === "OT"
          ? `${periodName}${displayPeriod > 1 ? displayPeriod : ""}`
          : numberAsString;
    } else {
      return inning != null
        ? `${inning % 1 === 0 ? "Top" : "Bottom"} ${numberAsString}`
        : includePeriodName
          ? `${numberAsString} ${periodName}`
          : numberAsString;
    }
  }

  public formatClock(options: { clockSeconds?: number } = {}) {
    let { clockSeconds } = options;
    if (clockSeconds == null) {
      clockSeconds = this.game.clockSeconds;
    }
    const minutes = Math.floor(clockSeconds / 60);
    const seconds = clockSeconds % 60;
    return `${minutes}:${padStart(seconds.toString(), 2, "0")}`;
  }

  public get allPeriods(): number[] {
    const allPeriods = Array.from(
      Array(Math.max(this.game.periodsRegulation, this.game.period)),
      (_, i) => i + 1
    );
    return allPeriods;
  }

  public get periods(): number[] {
    const periods = Array.from(Array(this.game.period), (_, i) => i + 1);
    return periods;
  }

  public get lineScore(): LiveStatsGameLinescore {
    return {
      teamA: this.game.visitingTeam,
      teamB: this.game.homeTeam,
      values: this.allPeriods
        .map(i => {
          const visitingTeam = this.game.visitingTeam.periodScores[i - 1];
          const homeTeam = this.game.homeTeam.periodScores[i - 1];
          return {
            label: this.toPeriodLabel(i),
            valueA: (visitingTeam == null ? "" : visitingTeam).toString(),
            valueB: (homeTeam == null ? "" : homeTeam).toString()
          };
        })
        .concat(
          this.game.type === "BaseballSoftballGame"
            ? [
              {
                label: "R",
                valueA: this.stats.visitingTeam.totals.byKey("runs").value,
                valueB: this.stats.homeTeam.totals.byKey("runs").value
              },
              {
                label: "H",
                valueA: this.stats.visitingTeam.totals.byKey("hits").value,
                valueB: this.stats.homeTeam.totals.byKey("hits").value
              },
              {
                label: "E",
                valueA: this.stats.visitingTeam.totals.byKey("errors").value,
                valueB: this.stats.homeTeam.totals.byKey("errors").value
              }
            ]
            : [
              {
                label: "T",
                valueA: this.game.visitingTeam.score.toString(),
                valueB: this.game.homeTeam.score.toString()
              }
            ]
        )
    };
  }

  public compare(
    itemA: LiveStatsStatsGrid,
    itemB: LiveStatsStatsGrid,
    options: { excludeKeys?: string[] } = {}
  ) {
    assertType(
      itemA,
      LiveStatsStatsGrid,
      "itemA must be an instance of LiveStatsStatsGrid"
    );
    assertType(
      itemA,
      LiveStatsStatsGrid,
      "itemB must be an instance of LiveStatsStatsGrid"
    );
    let comparisions = LiveStatsStatsGrid.compare(itemA, itemB);

    if (options.excludeKeys != null) {
      comparisions = comparisions.filter(
        c => options.excludeKeys.indexOf(c.key) === -1
      );
    }
    return comparisions;
  }

  toPeriodLabel(period: number) {
    const { periodsRegulation } = this.game;
    const { showExtraPeriodsAsOt } = this.game.rules;
    const firstOTPeriod = periodsRegulation + 1;

    return period <= periodsRegulation || !showExtraPeriodsAsOt
      ? period.toString()
      : period === firstOTPeriod
        ? "OT"
        : "OT" + (period - periodsRegulation);
  }
}

function parseClockToSeconds(clock: string): number {
  if (clock.includes(":")) {
    const [minute, second] = clock.split(":");
    return parseInt(minute, 10) * 60 + parseFloat(second);
  }
  return parseFloat(clock);
}

function toOrdinal(value: number) {
  if (value > 10 && value < 20) {
    return value + "th";
  }
  if (value % 10 === 1) {
    return value + "st";
  }
  if (value % 10 === 2) {
    return value + "nd";
  }
  if (value % 10 === 3) {
    return value + "rd";
  }
  return value + "th";
}

export interface LiveStatsGameLinescore {
  teamA: LiveStatsGameTeam;
  teamB: LiveStatsGameTeam;
  values: {
    label: string;
    valueA: number | string;
    valueB: number | string;
  }[];
}

interface ILiveStatsGameWrapper {
  game: LiveStatsGame;
  leaders: LiveStatsLeaders;
  stats: { homeTeam: LiveStatsStats; visitingTeam: LiveStatsStats };
  seasonStats: { homeTeam: LiveStatsSeasonStats; visitingTeam: LiveStatsSeasonStats };
  plays: LiveStatsPlay[];
  footballGame: LiveStatsFootballGameDetails;
}

export class LiveStatsStats {
  totals: LiveStatsStatsGrid;
  periodStats: LiveStatsStatsGrid[];
  playerGroups: { [x: string]: LiveStatsPlayerStatsGrid };
  players: LiveStatsPlayer[];

  constructor(input: any) {
    this.totals = new LiveStatsStatsGrid(input.totals);
    this.periodStats = input.periodStats.map(i => new LiveStatsStatsGrid(i));
    this.playerGroups = Object.keys(input.playerGroups).reduce(
      (agg, groupKey) => {
        agg[groupKey] = new LiveStatsPlayerStatsGrid(
          input.playerGroups[groupKey]
        );
        return agg;
      },
      {}
    );
    this.players = input.players;
  }
}

export class LiveStatsSeasonStats {
  totals: LiveStatsStatsGrid;
  playerGroups: { [x: string]: LiveStatsPlayerStatsGrid };
  players: LiveStatsPlayer[];

  constructor(input: any) {
    this.totals = new LiveStatsStatsGrid(input.totals);
    this.playerGroups = Object.keys(input.playerGroups).reduce(
      (agg, groupKey) => {
        agg[groupKey] = new LiveStatsPlayerStatsGrid(
          input.playerGroups[groupKey]
        );
        return agg;
      },
      {}
    );
    this.players = input.players;
  }
}

export function withLastScore(plays: LiveStatsPlay[]) {
  let lastScore = { visitingTeam: 0, homeTeam: 0 };
  return plays.slice().map(p => {
    if (p.score != null) {
      lastScore = p.score;
    }
    const withScore: LiveStatsPlay = {
      id: p.id,
      context: p.context,
      narrative: p.narrative,
      clockSeconds: p.clockSeconds,
      period: p.period,
      type: p.type,
      action: p.action,
      player: p.player,
      involvedPlayers: p.involvedPlayers,
      team: p.team,
      score: lastScore
    };
    return withScore;
  });
}

export class LiveStatsStatsGrid {
  public keys: string[];
  public abbreviations: string[];
  public fullNames: string[];
  public values: string[];

  constructor(input: any) {
    this.keys = Object.keys(input.key);
    this.abbreviations = Object.values(input.key);
    this.fullNames = Object.values(input.fullKey);
    this.values = Object.values(input.values).map((v, i) =>
      adjustStatValueFormat(this.keys[i], v)
    );
  }

  static compare(
    itemA: LiveStatsStatsGrid,
    itemB: LiveStatsStatsGrid
  ): LiveStatsCompare[] {
    assertType(itemA, LiveStatsStatsGrid, "itemA must be a LiveStatsStatsGrid");
    assertType(itemB, LiveStatsStatsGrid, "itemB must be a LiveStatsStatsGrid");
    return itemA.keys.map(k => {
      const a = itemA.byKey(k);
      const b = itemB.byKey(k);
      const asPercentage = fuzzyCompare(a.value, b.value);
      return {
        key: a.key,
        abbreviation: a.abbreviation,
        fullName: a.fullName,
        valueA: a.value,
        valueB: b.value,
        valueAPercentage: asPercentage,
        valueBPercentage: 1 - asPercentage
      };
    });
  }

  byKey(key: string) {
    const index = this.keys.indexOf(key);
    return {
      key: key,
      abbreviation: this.abbreviations[index],
      fullName: this.fullNames[index],
      value: this.values[index]
    };
  }
}

export interface Run {
  runTeam: LiveStatsGameTeam;
  timeDiff: string;
  start: PointInTime;
  end: PointInTime;
  homeTeam: TeamRun;
  visitingTeam: TeamRun;
  plays: LiveStatsPlay[];
  allPlaysInTime: LiveStatsPlay[];
  scoringPlays: LiveStatsPlay[];
}

export interface TeamRun {
  points: number;
  teamStats: {
    fg: { GOOD: number; MISS: number };
    three: { GOOD: number; MISS: number };
    ft: { GOOD: number; MISS: number };
    block: number;
    turnover: number;
    steal: number;
  };
  playerPoints: {
    name: string;
    points: number;
  }[];
}

export interface LiveStatsCompare {
  key: string;
  abbreviation: string;
  fullName: string;
  valueA: string;
  valueB: string;
  valueAPercentage: number;
  valueBPercentage: number;
}

function adjustStatValueFormat(statKey: string, value: any) {
  if (value === null) {
    return value;
  }
  if (value.replace) {
    return value.replace(/%$/g, "");
  }
  return value;
}
export interface LiveStatsPlayerStats {
  title: string;
  keys: string[];
  abbreviations: string[];
  descriptions: string[];
  values: LiveStatsPlayerStatsGridPlayer;
}

export class LiveStatsPlayerStatsGrid {
  public title: string;
  public keys: string[];
  public abbreviations: string[];
  public descriptions: string[];
  public players: LiveStatsPlayerStatsGridPlayer[];

  constructor(input: any) {
    this.title = input.title.replace(/(\w)([A-Z])/g, "$1 $2");

    this.keys = input.keys != null ? input.keys : Object.keys(input.key);

    this.abbreviations =
      input.keys != null ? input.abbreviations : Object.values(input.key);

    this.descriptions =
      input.keys != null
        ? input.descriptions
        : Object.values(input.descriptions);

    const players: { values: string[] }[] =
      input.keys != null
        ? input.players
        : input.values.map(p => ({
          values: Object.values(p).map((v: any, i: number) =>
            adjustStatValueFormat(this.keys[i], v)
          )
        }));

    const leaderValue = this.keys.map((_, i) =>
      players.reduce((playerMax, p) => {
        const numberValue = parseFloat(p.values[i]);
        if (numberValue > playerMax) {
          return numberValue;
        }
        return playerMax;
      }, 1)
    );

    this.players = players.map((p, i) => ({
      values: p.values,
      eqZero: p.values.map((v, i2) => {
        if (this.keys[i2] === "uni") {
          return false;
        }
        return (
          v === "0" || v === "0-0" || v === ".000" || v === "0.0" || v === "-"
        );
      }),
      leader: p.values.map((v, i2) => {
        if (["uni", "personalFouls", "turnovers"].includes(this.keys[i2])) {
          return false;
        }
        return parseFloat(v) === leaderValue[i2];
      })
    }));
  }

  summarize(keys: string[]) {
    const indexes = keys.map(k => this.keys.indexOf(k));
    const _keys = indexes.map(i => this.keys[i]);
    const abbreviations = indexes.map(i => this.abbreviations[i]);
    const descriptions = indexes.map(i => this.descriptions[i]);
    const players = this.players.map(p => ({
      values: indexes.map(i => p.values[i])
    }));

    return new LiveStatsPlayerStatsGrid({
      title: this.title,
      keys: _keys,
      abbreviations,
      descriptions,
      players
    });
  }
}

export class LiveStatsFootballGameDetails implements LiveStatsFootballGame {
  context: LiveStatsFootballContext;
  drives: LiveStatsFootballDrives[];
  scoringSummary: LiveStatsFootballScoringPlay[];

  constructor(
    inputFootballGame: LiveStatsFootballGame,
    plays: LiveStatsPlay[]
  ) {
    this.context = inputFootballGame.context;
    this.drives = inputFootballGame.drives.map(d =>
      Object.assign({}, d, {
        id: `${d.team}-${d.startQuarter}-${d.startTimeSeconds}`
      })
    );
    this.scoringSummary = this.generateScoringSummary(plays);
  }

  private generateScoringSummary(
    plays: LiveStatsPlay[]
  ): LiveStatsFootballScoringPlay[] {
    const scoringPlaysWeCareAbout = plays.filter(
      p => p.score != null && p.type !== "PAT"
    );
    return scoringPlaysWeCareAbout.map(play => {
      const indexOfOverall = plays.indexOf(play);
      const nextPlay = plays[indexOfOverall + 1];
      const patIfApplicable =
        nextPlay && nextPlay.type === "PAT" ? nextPlay : null;
      const followingDriveSummary = plays
        .slice(indexOfOverall)
        .find(p => p.type === "Drive End");

      const scoringPlay: LiveStatsFootballScoringPlay = {
        id: play.id,
        period: play.period,
        clockSeconds: play.clockSeconds,
        context: play.context,
        driveSummary:
          followingDriveSummary == null ? "" : followingDriveSummary.narrative,
        involvedPlayers: play.involvedPlayers,
        player: play.player,
        score:
          patIfApplicable && patIfApplicable.score
            ? patIfApplicable.score
            : play.score,
        team: play.team,
        action: play.action,
        type: play.type,
        narrative:
          play.narrative +
          (patIfApplicable == null ? "" : ` (${patIfApplicable.narrative})`)
      };
      return scoringPlay;
    });
  }
}

export interface LiveStatsFootballScoringPlay extends LiveStatsPlay {
  driveSummary: string;
}

export interface LiveStatsPlayerStatsGridPlayer {
  values: string[];
  eqZero: boolean[];
  leader: boolean[];
}

export function fuzzyParse(valueA: string) {
  let aAsNumber = parseFloat(valueA);
  if (valueA.includes(":")) {
    aAsNumber = parseClockToSeconds(valueA);
  }
  if (!isNaN(aAsNumber)) {
    return aAsNumber;
  }
  return 1;
}

function fuzzyCompare(valueA: string, valueB: string) {
  let aAsNumber = parseFloat(valueA);
  let bAsNumber = parseFloat(valueB);
  if (valueA.includes(":") || valueB.includes(":")) {
    aAsNumber = parseClockToSeconds(valueA);
    bAsNumber = parseClockToSeconds(valueB);
  }
  const total = aAsNumber + bAsNumber;
  if (!isNaN(total)) {
    return aAsNumber / total;
  }
  return 0.5;
}

export interface LiveStatsLeaders {
  categories: LiveStatsLeaderCategory[];
  homeTeam: LiveStatsTeamLeaders;
  visitingTeam: LiveStatsTeamLeaders;
}

export interface LiveStatsLeaderCategory {
  key: string;
  name: string;
}

export interface LiveStatsTeamLeaders {
  [x: string]: LiveStatsLeader[] | null;
}

export interface LiveStatsLeader {
  player: LiveStatsPlayer;
  value: string;
}

function teamPascalToCamel(value: TeamPascalKey): TeamCamelKey {
  if (value === "HomeTeam") {
    return "homeTeam";
  }
  if (value === "VisitingTeam") {
    return "visitingTeam";
  }
  return null;
}

export interface LiveStatsPlayer {
  team: TeamPascalKey;
  firstName: string;
  lastName: string;
  uniformNumber: string;
  photo: string | null;
  personId: string;
}
export interface LiveStatsGame {
  clientHostname: string;
  date: string;
  dateUtc: string;
  attendance: number;
  bannerMessage: string;
  source: string;
  startTime: string;
  endTime: string;
  period: number;
  clockSeconds: number;
  hasStarted: boolean;
  isComplete: boolean;
  isWomens: boolean;
  rules: LiveStatsGameRules;
  homeTeam: LiveStatsGameTeam;
  visitingTeam: LiveStatsGameTeam;
  lastPlays: LiveStatsPlay[];
  type:
  | "BasketballGame"
  | "BaseballSoftballGame"
  | "LacrosseGame"
  | "FootballGame"
  | "SoccerGame"
  | "IceHockeyGame"
  | "VolleyballGame";
  periodsRegulation: number;
  notes: string[];
  officials: string;
  situation: LiveStatsSituation;
  location: string;
  siteImage: string;
  globalSportShortname: string;
}

export interface LiveStatsFootballGame {
  context: LiveStatsFootballContext;
  drives: LiveStatsFootballDrives[];
}

export interface LiveStatsFootballContext {
  down: string;
  possession: TeamPascalKey;
  spot: string;
  toGo: string;
}

export interface LiveStatsFootballDrives {
  endHow: string;
  endQuarter: string;
  endSpot: string;
  endTimeSeconds: number;
  plays: number;
  startHow: string;
  startQuarter: string;
  startSpot: string;
  startTimeSeconds: number;
  topSeconds: number;
  team: TeamPascalKey;
  yards: number;
}

export interface LiveStatsGameTeam {
  id: string;
  name: string;
  logo: string;
  score: number;
  setScore: number;
  periodScores: number[];
  periodsFouls?: number[];
  color: string;
  textColor: string;
  currentRecord: string;
  batOrder: PlayerPosition[];
}

export interface LiveStatsTeamGame extends LiveStatsGameTeam {
  leaders: LiveStatsTeamLeaders;
}

export interface LiveStatsGameRules {
  clockDirection: "Up" | "Down" | "None";
  clockSegmentation: "Periods" | "Continuous";
  otMinutes: number;
  showExtraPeriodsAsOt: boolean;
  periodMinutes: number;
  periodName: "Quarter" | "Half" | "Period";
}

export interface LiveStatsSituation {
  inning: number;
  balls: number;
  strikes: number;
  outs: number;
  pitcher: LiveStatsPlayer;
  winPitcher: LiveStatsPlayer;
  lossPitcher: LiveStatsPlayer;
  savePitcher: LiveStatsPlayer;
  batter: LiveStatsPlayer;
  batterHandedness: string;
  onDeck: LiveStatsPlayer;
  pitcherPitchCount: number;
  pitcherHandedness: string;
  pitchingTeam: string;
  onFirst: LiveStatsPlayer;
  onSecond: LiveStatsPlayer;
  onThird: LiveStatsPlayer;
}

export interface PlayerPosition {
  player: LiveStatsPlayer;
  position: string;
}

export interface PointInTime {
  period: number;
  clockSeconds: number;
}

export interface LiveStatsPlay extends PointInTime {
  player: LiveStatsPlayer;
  involvedPlayers: LiveStatsPlayer[];
  team: TeamPascalKey;
  narrative: string;
  context: string;
  coordinate?: { side: string; x: number; y: number };
  id: string;
  type: string;
  action: string;
  score?: { homeTeam: number; visitingTeam: number };
  qualifiers?: string[];
}

function assert(condition: () => boolean, errorMessage: string) {
  if (isDevMode() && !condition()) {
    console.error(errorMessage);
  }
}

function assertType<T>(
  item: any,
  type: { new(...args: any[]): T },
  errorMessage: string
) {
  return assert(() => item == null || item instanceof type, errorMessage);
}

export interface AdsResponse {
  ads: Ads[];
}

export interface Ads {
  id: number;
  title: string;
  link: string;
  image: string;
  newWindow: boolean;
  html?: string;
}
