import { Component, OnDestroy, Inject } from "@angular/core";
import {
  LiveHubService,
  LiveHubGameViewModel,
  LiveHubTeamViewModel,
  LiveHubDateInfo,
  sorter,
} from "../../live-hub.service";
import { Observable, of, combineLatest, BehaviorSubject, Subject } from "rxjs";
import { scan, map, takeUntil } from "rxjs/operators";
import { ENV_HREF } from "../../app.component";
import * as moment from "moment";

const showDivisions = ["DI", "DII", "DIII", "NAIA"];

@Component({
  selector: "app-live-hub",
  templateUrl: "./live-hub.component.html",
  styleUrls: ["./live-hub.component.scss"],
})
export class LiveHubComponent implements OnDestroy {
  public liveGamesToday$: Observable<LiveHubGameViewModel[]>;
  public sports$: Observable<string[]>;
  public conferences$: Observable<string[]>;
  public divisions$: Observable<string[]>;

  public teams$: Observable<LiveHubTeamViewModel[]>;
  public logosVisible$: Observable<LiveHubTeamViewModel[]>;
  public logosCountToShow$ = new BehaviorSubject<number>(16 * 5);

  public sportsAbbrev$: Observable<string[]>;
  public filteredLiveGamesToday$: Observable<LiveHubGameViewModel[]>;
  public showLiveGamesToday$: Observable<LiveHubGameViewModel[]>;
  public selectedSport$ = new BehaviorSubject<string>("All");
  public selectedSportAbbrev$ = new BehaviorSubject<string>("All");
  public filterSchoolText$ = new BehaviorSubject<string>("");
  public selectedConference$ = new BehaviorSubject<string>("All");
  public selectedDivision$ = new BehaviorSubject<string>("All");
  public selectedTeam$ = new BehaviorSubject<LiveHubTeamViewModel>(null);
  public itemCountToShow$ = new BehaviorSubject<number>(20);
  public hasFilteredLiveEvents$: Observable<boolean>;
  public date = new Date();
  collapsed = true;
  default = true;
  destroyed$ = new Subject();
  showBackToTop = false;
  selectedTeam = false;
  now = moment().toDate();
  gamePastTime = moment(this.now).subtract(3, "hours").utc();
  gameFutureTime = moment(this.now).add(3, "hours").utc();

  toggleSelectSelectedTeam() {
    event.preventDefault();
    this.selectedTeam = !this.selectedTeam;
  }

  toggleFilter() {
    event.preventDefault();
    this.collapsed = !this.collapsed;
  }

  tabsCardView() {
    event.preventDefault();
    this.default = true;
  }

  tabsTableView() {
    event.preventDefault();
    this.default = false;
  }

  backToTop() {
    window.scrollTo(0, 0);
    this.showBackToTop = false;
  }

  onScrollUp() {
    this.showBackToTop = false;
  }

  onScrollDown() {
    this.showBackToTop = true;
  }

  eventIsLive(date_info: LiveHubDateInfo) {
    if (!date_info.tbd && !date_info.all_day) {
      if (
        moment(date_info.datetime_utc)
          .utc()
          .isBetween(this.gamePastTime, moment(this.now).utc())
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  constructor(
    liveHub: LiveHubService,
    @Inject(ENV_HREF) public envHref: string
  ) {
    this.liveGamesToday$ = liveHub.liveGamesToday$;

    this.sports$ = this.filterLiveGamesExcludingCriteria("sport").pipe(
      scan<LiveHubGameViewModel[], string[]>((acc, games) => {
        const set = new Set<string>(
          games
            .filter((game) => game.sport != null)
            .map((game) => game.sport.title)
        );
        return Array.from(set).sort();
      }, [] as string[])
    );
    this.sportsAbbrev$ = this.filterLiveGamesExcludingCriteria("sport").pipe(
      scan<LiveHubGameViewModel[], string[]>((acc, games) => {
        const set = new Set<string>(
          games
            .filter((game) => game.sport != null)
            .map((game) => game.sport.abbrev)
        );
        return Array.from(set).sort();
      }, [] as string[])
    );
    this.conferences$ = this.filterLiveGamesExcludingCriteria(
      "conference"
    ).pipe(
      scan<LiveHubGameViewModel[], string[]>((acc, games) => {
        const set = new Set<string>(
          games
            .reduce(
              (agg, game) =>
                agg.concat([
                  game.home_team.conference_name,
                  game.away_team.conference_name,
                ]),
              []
            )
            .filter((conferenceName) => conferenceName != null)
        );
        return Array.from(set).sort();
      }, [] as string[])
    );

    this.divisions$ = of(showDivisions.concat(["Other"]));

    this.teams$ = this.filterLiveGamesExcludingCriteria("school").pipe(
      map((games) => {
        const teamMap = new Map<number, LiveHubTeamViewModel>();

        games.forEach((game) => {
          if (!teamMap.has(game.home_team.school_global_id)) {
            teamMap.set(game.home_team.school_global_id, game.home_team);
          }
        });
        games.forEach((game) => {
          if (
            game.away_team != null &&
            !teamMap.has(game.away_team.school_global_id)
          ) {
            teamMap.set(game.away_team.school_global_id, game.away_team);
          }
        });

        return Array.from(
          Array.from(teamMap.values()).sort(
            sorter([(team) => team.sort_name], "asc")
          )
        );
      })
    );

    this.filteredLiveGamesToday$ = this.filterLiveGamesExcludingCriteria(null);

    this.hasFilteredLiveEvents$ = combineLatest(
      this.liveGamesToday$,
      this.filteredLiveGamesToday$
    ).pipe(map(([all, filtered]) => all.length !== filtered.length));

    this.filteredLiveGamesToday$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.itemCountToShow$.next(20));

    this.selectedDivision$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.selectedConference$.next("All");
    });

    this.showLiveGamesToday$ = combineLatest(
      this.filteredLiveGamesToday$,
      this.itemCountToShow$
    ).pipe(
      map(([allTheGames, itemsToShow]) => allTheGames.slice(0, itemsToShow))
    );

    this.logosVisible$ = combineLatest(
      this.teams$,
      this.logosCountToShow$
    ).pipe(map(([teams, toShow]) => teams.slice(0, toShow)));
  }

  reset() {
    this.filterSchoolText$.next("");
    this.selectedConference$.next("All");
    this.selectedDivision$.next("All");
    this.selectedSport$.next("All");
    this.selectedSportAbbrev$.next("All");
    this.filterSchoolText$.next("");
    this.selectedTeam$.next(null);
    this.itemCountToShow$.next(20);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  showMore() {
    this.itemCountToShow$.next(this.itemCountToShow$.value + 20);
  }

  showMoreLogos() {
    this.logosCountToShow$.next(this.logosCountToShow$.value + 16 * 2);
  }

  private filterLiveGamesExcludingCriteria(
    excludeCriteria:
      | null
      | "sport"
      | "conference"
      | "division"
      | "text"
      | "school"
  ) {
    return combineLatest(
      this.liveGamesToday$,
      this.selectedSport$,
      this.selectedConference$,
      this.selectedDivision$,
      this.selectedTeam$,
      this.filterSchoolText$
    ).pipe(
      scan<
        [
          LiveHubGameViewModel[],
          string,
          string,
          string,
          LiveHubTeamViewModel,
          string
        ],
        LiveHubGameViewModel[]
      >((acc, value) => {
        const [games, sport, conference, division, team, filterText] = value;
        return games.filter((game) => {
          if (
            excludeCriteria !== "sport" &&
            sport !== "All" &&
            (game.sport == null || game.sport.title !== sport)
          ) {
            return false;
          }
          if (
            excludeCriteria !== "conference" &&
            conference !== "All" &&
            game.home_team.conference_name !== conference &&
            game.away_team.conference_name !== conference
          ) {
            return false;
          }
          if (
            excludeCriteria !== "division" &&
            division !== "All" &&
            ((division !== "Other" && game.division.name !== division) ||
              (division === "Other" &&
                showDivisions.includes(game.division.name)))
          ) {
            return false;
          }
          if (
            excludeCriteria !== "text" &&
            filterText !== "" &&
            !(
              game.home_team.name
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              game.away_team.name
                .toLowerCase()
                .includes(filterText.toLowerCase())
            )
          ) {
            return false;
          }
          if (
            excludeCriteria !== "school" &&
            team != null &&
            game.home_team.school_global_id !== team.school_global_id &&
            (game.away_team == null ||
              game.away_team.school_global_id !== team.school_global_id)
          ) {
            return false;
          }
          return true;
        });
      }, [] as LiveHubGameViewModel[])
    );
  }

  public gameId(game: LiveHubGameViewModel): string {
    return `${game.client_id}-${game.id}`;
  }
}
