import {
  Component,
  OnInit,
  Inject
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  TeamPascalKey
} from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-play-by-play",
  templateUrl: "./play-by-play.component.html",
  styleUrls: ["./play-by-play.component.scss"]
})
export class PlayByPlayComponent implements OnInit {
  selectedTeam: TeamPascalKey = null;
  selectedPlayer: string = null;
  selectDisabled = true;
  isFiltersActive = false;

  selectedPlayType: string = null;
  selectedPlayAction: string = null;

  playTypes$: Observable<string[]>;
  playActions$: Observable<string[]>;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(value => {
      if (value.playType) {
        this.selectedPlayType = value.playType;
      }
    });
    this.playTypes$ = this.game$.pipe(
      map(game => {
        const plays = game.plays;
        const set = new Set(plays.map(p => p.type));
        return ["Scoring"].concat(Array.from(set));
      })
    );
    this.playActions$ = this.game$.pipe(
      map(game => {
        const plays = game.plays;
        const set = new Set(
          plays
            .filter(p => p.action != null && p.action !== "")
            .map(p => p.action)
        );
        return Array.from(set);
      })
    );
  }

  ngOnInit() {}

  toggleFilters() {
    this.isFiltersActive = !this.isFiltersActive;
  }

  onChange(event) {
    if (event) {
      this.selectDisabled = false;
    }
  }

  resetPlayFiltersForm(userForm: NgForm) {
    userForm.resetForm();
  }
}
