import {
  Component,
  OnInit,
  Inject,
  Input,
  HostBinding,
  ViewContainerRef,
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsPlayerStatsGrid,
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-game-qb-prog-plays",
  templateUrl: "./game-qb-prog-plays.component.html",
  styleUrls: ["./game-qb-prog-plays.component.scss"],
})
export class GameQbProgPlaysComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];

  @Input() player;
  @Input() stats: LiveStatsPlayerStatsGrid;

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays;
  }
  get game() {
    return this._game;
  }

  get playerPlays(): LiveStatsPlay[] {
    const {
      player: foundPlayer,
      team,
    } = this._game.findPlayerFromIndividiualStats(this.player, this.stats.keys);
    return this._plays.filter((p) => {
      if (p.type == null) {
        return false;
      }
      if (p.type.toUpperCase() !== "PASS") {
        return false;
      }
      if (p.narrative.includes("sacked")) {
        return false;
      }
      if (p.player) {
        if (
          foundPlayer.team === p.player.team &&
          foundPlayer.firstName === p.player.firstName &&
          foundPlayer.lastName === p.player.lastName &&
          foundPlayer.uniformNumber === p.player.uniformNumber &&
          p.involvedPlayers.length > 0
        ) {
          return false;
        }
      }
      if (
        foundPlayer &&
        (p.player == null ||
          [p.player]
            .concat(p.involvedPlayers)
            .find(
              (pl) =>
                pl.team === foundPlayer.team &&
                pl.uniformNumber === foundPlayer.uniformNumber
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get playerSackPlays(): LiveStatsPlay[] {
    const {
      player: foundPlayer,
      team,
    } = this._game.findPlayerFromIndividiualStats(this.player, this.stats.keys);
    return this._plays.filter((p) => {
      if (p.type == null) {
        return false;
      }
      if (p.type.toUpperCase() !== "PASS") {
        return false;
      }
      if (!p.narrative.includes("sacked")) {
        return false;
      }
      if (
        foundPlayer &&
        (p.player == null ||
          [p.player]
            .concat(p.involvedPlayers)
            .find(
              (pl) =>
                pl.team === foundPlayer.team &&
                pl.uniformNumber === foundPlayer.uniformNumber
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    this.game$.subscribe((g) => (this.game = g));
  }

  ngOnInit() {}
}
