import { Component, OnInit, Input } from "@angular/core";
import {
  LiveStatsGameWrapper
} from "../live-stats-service.service";

@Component({
  selector: "app-game-recap",
  templateUrl: "./game-recap.component.html",
  styleUrls: ["./game-recap.component.scss"]
})
export class GameRecapComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  constructor() {}

  ngOnInit() {}
}
