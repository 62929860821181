import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppComponent } from "./app.component";
import { GameLinescoreComponent } from "./game-linescore/game-linescore.component";
import { LiveStatsService } from "./live-stats-service.service";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { GameStatusComponent } from "./game-status/game-status.component";
import { StatComparisonComponent } from "./stat-comparison/stat-comparison.component";
import { MainDetailsHeaderComponent } from "./main-details-header/main-details-header.component";
import { GameTeamLeadersComponent } from "./game-team-leaders/game-team-leaders.component";
import { GameLatestPlaysComponent } from "./game-latest-plays/game-latest-plays.component";
import { SummaryComponent } from "./pages/summary/summary.component";
import { RoutingModule } from "./routing/routing.module";
import { TeamStatsComponent } from "./pages/team-stats/team-stats.component";
import { SettingsMenuComponent } from "./settings-menu/settings-menu.component";
import { GameDetailsComponent } from "./game-details/game-details.component";
import { GamePlaysComponent } from "./game-plays/game-plays.component";
import { IndividualComponent } from "./pages/individual/individual.component";
import { LeadersComponent } from "./pages/leaders/leaders.component";
import { PlayByPlayComponent } from "./pages/play-by-play/play-by-play.component";
import { ImageComponent } from "./image/image.component";
import { GameIndividualStatsComponent } from "./game-individual-stats/game-individual-stats.component";
import { GamePlayerModalComponent } from "./game-player-modal/game-player-modal.component";
import { MediaBoxComponent } from "./pages/media-box/media-box.component";
import { ModalDialogModule } from "ngx-modal-dialog";
import { TooltipDirective } from "../tooltip.directive";
import { StickyModule } from "ng2-sticky-kit";
import { ResponsiveModule } from "ngx-responsive";
import { ClickOutsideModule } from "ng-click-outside";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import "rxjs/add/operator/do";
import { RunSummaryComponent } from "./run-summary/run-summary.component";
import { PlaysListComponent } from "./plays-list/plays-list.component";
import { GameSituationComponent } from "./game-situation/game-situation.component";
import { GameBaseballDiamondComponent } from "./game-baseball-diamond/game-baseball-diamond.component";
import { GameTeamStartersComponent } from "./game-team-starters/game-team-starters.component";
import { TabsComponent } from "./tabs/tabs.component";
import { TabComponent } from "./tab/tab.component";
import { SpaceToNbspPipe } from "./space-to-nbsp.pipe";
import { GameTrendComponent } from "./game-trend/game-trend.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { GameRecapComponent } from "./game-recap/game-recap.component";
import { GameDfpAdsComponent } from "./game-dfp-ads/game-dfp-ads.component";
import { DriveSummariesComponent } from "./pages/drive-summaries/drive-summaries.component";
import { GameDrivesComponent } from "./game-drives/game-drives.component";
import { PrintStatsComponent } from "./pages/print-stats/print-stats.component";
import { AppService } from "./app.service";
import { GameBaseballCountComponent } from "./game-baseball-count/game-baseball-count.component";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ShotChartComponent } from './pages/shot-chart/shot-chart.component';
import { GameBasketballCourtComponent } from './game-basketball-court/game-basketball-court.component';
import { LiveHubService } from "./live-hub.service";
import { LiveHubComponent } from "./pages/live-hub/live-hub.component";
import { LiveHubGamesComponent } from './live-hub-games/live-hub-games.component';
import { GameSoccerFieldComponent } from './game-soccer-field/game-soccer-field.component';
import { GameDrivesChartComponent } from './game-drives-chart/game-drives-chart.component';
import { GameQbProgComponent } from './game-qb-prog/game-qb-prog.component';
import { GameQbProgPlaysComponent } from './game-qb-prog-plays/game-qb-prog-plays.component';

const config = {
  breakPoints: {
    xs: { max: 639 },
    sm: { min: 640, max: 1023 },
    md: { min: 1024, max: 1200 },
    lg: { min: 1201, max: 1440 },
    xl: { min: 1441 }
  },
  debounceTime: 10
};

@NgModule({
  declarations: [
    AppComponent,
    GameLinescoreComponent,
    GameStatusComponent,
    StatComparisonComponent,
    MainDetailsHeaderComponent,
    GameTeamLeadersComponent,
    GameLatestPlaysComponent,
    SummaryComponent,
    TeamStatsComponent,
    SettingsMenuComponent,
    GameDetailsComponent,
    GamePlaysComponent,
    IndividualComponent,
    LeadersComponent,
    PlayByPlayComponent,
    ImageComponent,
    GameIndividualStatsComponent,
    GamePlayerModalComponent,
    MediaBoxComponent,
    RunSummaryComponent,
    PlaysListComponent,
    GameSituationComponent,
    GameBaseballDiamondComponent,
    TooltipDirective,
    GameTeamStartersComponent,
    TabsComponent,
    TabComponent,
    SpaceToNbspPipe,
    GameTrendComponent,
    GameRecapComponent,
    GameDfpAdsComponent,
    DriveSummariesComponent,
    GameDrivesComponent,
    PrintStatsComponent,
    GameBaseballCountComponent,
    ShotChartComponent,
    GameBasketballCourtComponent,
    LiveHubComponent,
    LiveHubGamesComponent,
    GameSoccerFieldComponent,
    GameDrivesChartComponent,
    GameQbProgComponent,
    GameQbProgPlaysComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RoutingModule,
    ModalDialogModule.forRoot(),
    StickyModule,
    NgxChartsModule,
    ResponsiveModule.forRoot(config),
    ClickOutsideModule,
    InfiniteScrollModule,
    ScrollingModule
  ],
  providers: [
    AppService,
    LiveStatsService,
    LiveHubService,
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [RunSummaryComponent, GamePlayerModalComponent]
})
export class AppModule {}
