import {
  Component,
  Input,
  Inject,
  ViewContainerRef,
  HostListener
} from "@angular/core";
import {
  LiveStatsPlayerStatsGrid,
  LiveStatsPlayerStatsGridPlayer,
  LiveStatsGameWrapper,
  fuzzyParse
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { first } from "rxjs/operators";
import { Observable } from "rxjs";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";
import { ModalDialogService } from "ngx-modal-dialog";

@Component({
  selector: "app-game-individual-stats",
  templateUrl: "./game-individual-stats.component.html",
  styleUrls: ["./game-individual-stats.component.scss"]
})
export class GameIndividualStatsComponent {
  @Input() stats: LiveStatsPlayerStatsGrid;
  @Input() teamStats;
  @Input() layoutType: string;
  game: LiveStatsGameWrapper;
  highlightedRow: number = null;
  sortByIndex: number = null;
  sortDirection: number = null;

  @HostListener("mouseleave")
  onMouseLeave() {
    this.highlightedRow = null;
  }
  onMouseEnter(index: number) {
    this.highlightedRow = index;
  }

  get leftStuckCols() {
    return [0, 1];
  }

  get centerCols() {
    if (this.stats.keys[this.stats.keys.length - 1] === 'personId') {
      return this.stats.keys.map((_, i) => i).slice(0, -1);
    } else {
      return this.stats.keys.map((_, i) => i);
    }
  }

  get rows(): LiveStatsPlayerStatsGridPlayer[] {
    let players = this.stats.players.slice();
    if (this.sortByIndex != null) {
      const i = this.sortByIndex;
      const dir = this.sortDirection;

      players = players.sort((a, b) => {
        const valueA = fuzzyParse(a.values[i]);
        const valueB = fuzzyParse(b.values[i]);
        if (valueA > valueB) {
          return -dir;
        }
        if (valueB > valueA) {
          return dir;
        }
        return 0;
      });
    }
    return players;
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) private game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(g => (this.game = g));
  }

  setSortByIndex(index: number) {
    if (this.sortByIndex !== index) {
      this.sortByIndex = index;
      this.sortDirection = 1;
    } else if (this.sortByIndex === index && this.sortDirection === 1) {
      this.sortByIndex = index;
      this.sortDirection = -1;
    } else {
      this.sortByIndex = null;
      this.sortDirection = null;
    }
  }

  showPlayerModal(player: LiveStatsPlayerStatsGridPlayer) {
    this.game$.pipe(first()).subscribe(game => {
      const { player: foundPlayer, team } = game.findPlayerFromIndividiualStats(
        player,
        this.stats.keys
      );

      this.modalService.openDialog(this.viewRef, {
        title: `${team.name} - ${foundPlayer.firstName} ${
          foundPlayer.lastName
        } Stats`,
        childComponent: GamePlayerModalComponent,
        data: foundPlayer
      });
    });
  }
}
