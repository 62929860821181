import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spaceToNbsp"
})
export class SpaceToNbspPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/^\s/, "&nbsp;&nbsp;&nbsp;").replace(/\s/g, "&nbsp;");
  }
}
