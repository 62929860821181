import { Component, Input, OnInit } from "@angular/core";
import { Tab } from "../tab.interface";

@Component({
  selector: "app-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"]
})
export class TabComponent implements Tab {
  selected: boolean;
  @Input() tabTeam: string;
  @Input() tabID: string;
  @Input() tabTitle;

  constructor() {}
}
