import { NgZone } from "@angular/core";

const _scriptCache = new Map();

/**
 * Load an external script.
 *
 * @param {string} url Absolute URL of script to load
 * @param {string=} name Name of global variable that the script is expected to define
 * @return {Promise}
 */
export function loadScript(url, name): Promise<any> {
  let promise;

  if (_scriptCache.has(url)) {
    // TODO: normalize URL
    promise = _scriptCache.get(url);
  } else {
    promise = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.onerror = event => reject(new Error(`Failed to load '${url}'`));
      script.onload = resolve;
      script.async = true;
      script.src = url;

      if (document.currentScript) {
        document.currentScript.parentNode.insertBefore(
          script,
          document.currentScript
        );
      } else {
        (document.head || document.getElementsByTagName("head")[0]).appendChild(
          script
        );
      }
    });

    _scriptCache.set(url, promise);
  }

  return promise.then(() => {
    if (window[name]) {
      return window[name];
    } else {
      throw new Error(`"${name}" was not created by "${url}"`);
    }
  });
}

export function sidearmSignalr(
  basePath: string,
  zone: NgZone
): Promise<SidearmSignalrConnection> {
  return loadScript(
    "https://v2-realtime.sidearmsports.com/js/sidearm.realtime.xdm.js",
    "sidearm"
  )
    .then(sidearm => new sidearm.realtime("livestats/" + basePath))
    .then((instance: SidearmSignalrConnectionInternal) => {
      return new Promise<SidearmSignalrConnectionInternal>(resolve => {
        instance.ready(() => {
          setTimeout(() => {
            resolve(instance);
          }, 1000);
        });
      });
    })
    .then(internal => new SidearmSignalrConnection(internal, zone));
}

interface SidearmSignalrConnectionInternal {
  socket: { destroy: () => void };
  handlers: { [name: string]: ((data: any) => void)[] };
  ready(callback: () => void): void;
  listen(path: string, handler: (data: any) => void): void;
}

export class SidearmSignalrConnection {
  private isDestroyed = false;

  constructor(
    private internal: SidearmSignalrConnectionInternal,
    private zone: NgZone
  ) {}

  listen(path: string, handler: (data: any) => void): void {
    if (this.isDestroyed) {
      throw new Error(
        "cannot listen after socket is destroyed, create a new socket instead"
      );
    }
    this.internal.listen(path, data => {
      this.zone.run(() => {
        handler(data);
      });
    });
  }

  destroy() {
    this.isDestroyed = true;
    this.internal.socket.destroy();
    this.internal.handlers = {};
  }
}
