import { Component, OnInit, Input } from "@angular/core";
import { LiveStatsGameWrapper } from "../live-stats-service.service";

@Component({
  selector: "app-game-status",
  templateUrl: "./game-status.component.html",
  styleUrls: ["./game-status.component.scss"],
})
export class GameStatusComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  constructor() {}

  ngOnInit() {}
}
