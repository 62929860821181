import {
  Component,
  OnInit,
  Inject,
  ViewContainerRef,
  Input
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  TeamCamelKey,
  withLastScore
} from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable,  BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { entries, groupBy } from "lodash";

@Component({
  selector: "app-print-stats",
  templateUrl: "./print-stats.component.html",
  styleUrls: ["./print-stats.component.scss"]
})
export class PrintStatsComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];
  title = "";
  home = true;
  away = true;
  offense = true;
  defense = true;
  special = true;
  players = true;
  starters = true;
  subs = true;
  goalies = true;
  batting = true;
  fielding = true;
  pitching = true;
  totals = true;
  private _selectedPlayPeriod = new BehaviorSubject<number[]>([]);
  selectedPlayPeriod$ = this._selectedPlayPeriod.asObservable();
  summary = true;
  linescore = true;
  notes = true;

  public periods: PeriodsViewModel[];

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays.slice().reverse();
  }
  get game() {
    return this._game;
  }

  get filteredPlaysByPeriod(): { period: number; plays: LiveStatsPlay[] }[] {
    return entries(groupBy(this._plays, p => p.period))
      .map(([periodString, plays]) => {
        return {
          period: Number(periodString),
          plays
        };
      });
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(g => {
      this.game = g;

      this.periods = g.periods.map(period => ({
          period,
          selected: true
      }));

      this.periodChanged();
    });
  }

  lasts$: Observable<{ [team: string]: Lasts }> = this.game$.pipe(
    map(game => {
      const agg: { [team: string]: Lasts } = {};

      ["VisitingTeam", "HomeTeam"].forEach(key => {
        const camelKey: TeamCamelKey = <TeamCamelKey>(
          (key[0].toLowerCase() + key.substring(1))
        );
        const otherCamelKey: TeamCamelKey =
          camelKey === "visitingTeam" ? "homeTeam" : "visitingTeam";

        const plays = withLastScore(game.plays).reverse();
        const scoringPlays = plays.filter(p => p.score != null);

        const lastFg = scoringPlays.find(
          p => p.type !== "FT" && p.action === "GOOD" && p.team === key
        );

        const largeLead = scoringPlays
          .filter(p => p.score[camelKey] > p.score[otherCamelKey])
          .sort((a, b) => {
            const usA = a.score[camelKey];
            const themA = a.score[otherCamelKey];
            const usB = b.score[camelKey];
            const themB = b.score[otherCamelKey];

            const leadA = usA - themA;
            const leadB = usB - themB;

            return leadB - leadA;
          })[0];

        const lastLead = scoringPlays.filter(play => {
          const us = play.score[camelKey];
          const them = play.score[otherCamelKey];

          return us > them;
        })[0];

        const leadsTime = ``;

        const leadsText = `
        Leads: ${game.stats[camelKey].totals.byKey("leads").value} /
        ${game.stats[camelKey].totals.byKey("timeWithLead").value}
        (${game.stats[camelKey].totals.byKey("percentLead").value}%) /
        Ties: ${game.stats[camelKey].totals.byKey("ties").value}`;

        agg[camelKey] = {
          fg: {
            time: (lastFg && this.playClockText(lastFg)) || "-",
            text: (lastFg && this.playText(lastFg)) || "-"
          },
          largeLead: {
            time: (largeLead && this.playClockText(largeLead)) || "-",
            text:
              (largeLead &&
                this.playScore(largeLead, camelKey, otherCamelKey) +
                  ` (${largeLead.score[camelKey] -
                    largeLead.score[otherCamelKey]} pts)`) ||
              "-"
          },
          lastLead: {
            time: (lastLead && this.playClockText(lastLead)) || "-",
            text:
              (lastLead && this.playScore(lastLead, camelKey, otherCamelKey)) ||
              "-"
          },
          leadsTime: {
            time: " ",
            text: leadsText
          }
        };
      });

      return agg;
    })
  );

  playClockText(play: LiveStatsPlay): string {
    return `${this.game.formatClock({
      clockSeconds: play.clockSeconds
    })} ${this.game.formatPeriod({
      period: play.period,
      ordinal: true
    })}`;
  }

  playText(play: LiveStatsPlay): string {
    return `${play.narrative}`;
  }

  playScore(play: LiveStatsPlay, thisTeam: string, thatTeam: string): string {
    return `${play.score[thisTeam]}-${play.score[thatTeam]}`;
  }

  showHome(value) {
    this.home = value;
  }

  showAway(value) {
    this.away = value;
  }

  showOffense(value) {
    this.offense = value;
  }

  showDefense(value) {
    this.defense = value;
  }

  showSpecial(value) {
    this.special = value;
  }

  showPlayers(value) {
    this.players = value;
  }

  showStarters(value) {
    this.starters = value;
  }

  showSubs(value) {
    this.subs = value;
  }

  showGoalies(value) {
    this.goalies = value;
  }

  showBatting(value) {
    this.batting = value;
  }

  showFielding(value) {
    this.fielding = value;
  }

  showPitching(value) {
    this.pitching = value;
  }

  showTotals(value) {
    this.totals = value;
  }

  periodChanged() {
    let selectedPlayPeriod = this.periods
      .filter(period2 => period2.selected)
      .map(period2 => period2.period);

    if (selectedPlayPeriod.length === 0) {
      selectedPlayPeriod = this.periods.map(period2 => period2.period);
    }

    this._selectedPlayPeriod.next(selectedPlayPeriod);
  }

  showSummary(value) {
    this.summary = value;
  }

  showLinescore(value) {
    this.linescore = value;
  }

  showNotes(value) {
    this.notes = value;
  }

  printPage() {
    window.print();
  }

  ngOnInit() {}
}

interface Lasts {
  fg: { time: string; text: string };
  lastLead: { time: string; text: string };
  largeLead: { time: string; text: string };
  leadsTime: { time: string; text: string };
}

interface Trend {
  team: string;
  teamId: string;
  name: string;
  trends: MicroTrend[];
}
interface MicroTrend {
  text: string;
  qualifier: string;
  positiveNegative: MicroTrendType;
  plays: LiveStatsPlay[];
}

enum MicroTrendType {
  positive = "positive",
  negative = "negative"
}

interface PeriodsViewModel {
  period: number;
  selected: boolean;
}
