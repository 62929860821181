import { Component, OnInit, Inject, Input } from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  TeamPascalKey,
} from "../../live-stats-service.service";
import { groupBy, entries } from "lodash";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable, BehaviorSubject } from "rxjs";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-shot-chart",
  templateUrl: "./shot-chart.component.html",
  styleUrls: ["./shot-chart.component.scss"],
})
export class ShotChartComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];
  selectedTeam: TeamPascalKey = null;
  selectedPlayer: string = null;
  selectDisabled = true;
  isFiltersActive = false;
  showShotChart = true;
  showHeatMap = false;
  selectedChart = "fieldChart";

  private _selectedShotType = new BehaviorSubject<string[]>([]);
  selectedShotType$ = this._selectedShotType.asObservable();
  selectedShotType: string[];
  public types: ShotTypeViewModel[];

  private _selectedShotAction = new BehaviorSubject<string[]>([]);
  selectedShotAction$ = this._selectedShotAction.asObservable();
  selectedShotAction: string[];
  public actions: ActionTypeViewModel[];

  private _selectedPlayPeriod = new BehaviorSubject<number[]>([]);
  selectedPlayPeriod$ = this._selectedPlayPeriod.asObservable();
  selectedPlayPeriod: number[];
  public periods: PeriodsViewModel[];

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays.slice().reverse();
  }
  get game() {
    return this._game;
  }

  get scoringShotTypes(): LiveStatsPlay[] {
    return this._plays.filter((p) => {
      if (this._game.type === "SoccerGame") {
        if (p.type === "GOAL" || p.type === "SHOT" || p.type === "HDR") {
          return true;
        }
        return false;
      } else {
        if (
          p.score === null ||
          p.type === "FT" ||
          p.type === "GOOD" ||
          p.type === "MISS" ||
          p.type === "DUNK" ||
          p.type === "LAYUP"
        ) {
          return false;
        }
      }
      return true;
    });
  }

  get shotTypes(): { type: string }[] {
    return entries(groupBy(this.scoringShotTypes, (p) => p.type)).map(
      ([shotType]) => {
        return {
          type: shotType,
        };
      }
    );
  }

  get scoringShotActions(): LiveStatsPlay[] {
    return this._plays.filter((p) => {
      if (p.action === "MISS" || p.action === "GOOD") {
        return true;
      }
    });
  }

  get shotActions(): { action: string }[] {
    return entries(groupBy(this.scoringShotActions, (p) => p.action)).map(
      ([shotAction]) => {
        return {
          action: shotAction,
        };
      }
    );
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    this.game$.subscribe((g) => {
      this.game = g;

      this.periods = g.periods.map((period) => ({
        period,
        selected: true,
      }));

      this.periodChanged();
    });

    this.types = this.shotTypes.map((p) => ({
      type: p.type,
      selected: true,
    }));

    this.shotTypeChanged();

    this.actions = this.shotActions.map((p) => ({
      action: p.action,
      selected: true,
    }));

    this.shotActionChanged();
  }

  setChart(value) {
    this.selectedChart = value;
  }

  periodChanged() {
    this.selectedPlayPeriod = this.periods
      .filter((period2) => period2.selected)
      .map((period2) => period2.period);

    if (this.selectedPlayPeriod.length === 0) {
      this.selectedPlayPeriod = this.periods.map((period2) => period2.period);
    }

    this._selectedPlayPeriod.next(this.selectedPlayPeriod);
  }

  shotTypeChanged() {
    this.selectedShotType = this.types
      .filter((shotType2) => shotType2.selected)
      .map((shotType2) => shotType2.type);

    if (this.selectedShotType.length === 0) {
      this.selectedShotType = this.types.map((shotType2) => shotType2.type);
    }
    this._selectedShotType.next(this.selectedShotType);
  }

  shotActionChanged() {
    this.selectedShotAction = this.actions
      .filter((shotAction2) => shotAction2.selected)
      .map((shotAction2) => shotAction2.action);

    if (this.selectedShotAction.length === 0) {
      this.selectedShotAction = this.actions.map(
        (shotAction2) => shotAction2.action
      );
    }
    this._selectedShotAction.next(this.selectedShotAction);
  }

  toggleFilters() {
    this.isFiltersActive = !this.isFiltersActive;
  }

  showChart() {
    this.showShotChart = !this.showShotChart;
  }

  showMap() {
    this.showHeatMap = !this.showHeatMap;
  }

  onChange(event) {
    if (event) {
      this.selectDisabled = false;
    }
  }

  resetShotFiltersForm(userForm: NgForm) {
    userForm.resetForm();
  }

  ngOnInit() {}
}

interface PeriodsViewModel {
  period: number;
  selected: boolean;
}

interface ShotTypeViewModel {
  type: string;
  selected: boolean;
}

interface ActionTypeViewModel {
  action: string;
  selected: boolean;
}
