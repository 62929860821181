import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewContainerRef
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsPlayerStatsGrid,
  LiveStatsPlayerStatsGridPlayer
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";
import { ModalDialogService } from "ngx-modal-dialog";

@Component({
  selector: 'app-game-qb-prog',
  templateUrl: './game-qb-prog.component.html',
  styleUrls: ['./game-qb-prog.component.scss']
})
export class GameQbProgComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];

  @Input() stats: LiveStatsPlayerStatsGrid;
  @Input() teamLogo: string;

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays;
  }
  get game() {
    return this._game;
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(g => (this.game = g));
  }

  playerPhoto(player: LiveStatsPlayerStatsGridPlayer) {
    let photo;
    this.game$.pipe(first()).subscribe(game => {
      const { player: foundPlayer, team } = game.findPlayerFromIndividiualStats(
        player,
        this.stats.keys
      );
      photo = foundPlayer.photo;
    });
    return photo;
  }

  showPlayerModal(player: LiveStatsPlayerStatsGridPlayer) {
    this.game$.pipe(first()).subscribe(game => {
      const { player: foundPlayer, team } = game.findPlayerFromIndividiualStats(
        player, 
        this.stats.keys
      );

      this.modalService.openDialog(this.viewRef, {
        title: `${team.name} - ${foundPlayer.firstName} ${
          foundPlayer.lastName
        } Stats`,
        childComponent: GamePlayerModalComponent,
        data: foundPlayer
      });
    });
  }

  ngOnInit() {
  }

}
