import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewContainerRef,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsPlayer,
  LiveStatsGameTeam,
  TeamPascalKey,
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable, ReplaySubject } from "rxjs";
import { ModalDialogService } from "ngx-modal-dialog";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";
import { ENV_HREF } from "../app.component";

@Component({
  selector: "app-game-soccer-field",
  templateUrl: "./game-soccer-field.component.html",
  styleUrls: ["./game-soccer-field.component.scss"],
})
export class GameSoccerFieldComponent implements OnInit, OnChanges, OnDestroy {
  game: LiveStatsGameWrapper;
  plays: LiveStatsPlay[] = [];
  drawSubscription;

  @Input()
  selectedChart: string;
  @Input()
  selectedTeam: TeamPascalKey;
  @Input()
  selectedPlayer: string;
  @Input()
  type: string;
  @Input()
  action: string;
  @Input()
  selectedPeriods: number[];

  get filteredPlays(): LiveStatsPlay[] {
    return this.plays.filter((p) => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (!this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (!this.type.includes(p.type)) {
        return false;
      }
      if (!p.coordinate) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player].find(
            (pl) =>
              pl.team === this.selectedTeam &&
              pl.uniformNumber === this.selectedPlayer
          ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get goalPlays(): LiveStatsPlay[] {
    return this.plays.filter((p) => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (!this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (p.type !== "GOAL") {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player].find(
            (pl) =>
              pl.team === this.selectedTeam &&
              pl.uniformNumber === this.selectedPlayer
          ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get shotPlays(): LiveStatsPlay[] {
    return this.plays.filter((p) => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (!this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (p.type !== "SHOT" && p.type !== "HDR") {
        return false;
      }
      if (
        !p.qualifiers.includes("goalmouth:outleft") &&
        !p.qualifiers.includes("goalmouth:outhighleft") &&
        !p.qualifiers.includes("goalmouth:outhigh") &&
        !p.qualifiers.includes("goalmouth:outhighright") &&
        !p.qualifiers.includes("goalmouth:outright") &&
        !p.qualifiers.includes("goalmouth:woodworkleft") &&
        !p.qualifiers.includes("goalmouth:woodworkhigh") &&
        !p.qualifiers.includes("goalmouth:woodworkright") &&
        !p.qualifiers.includes("blocked")
      ) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player].find(
            (pl) =>
              pl.team === this.selectedTeam &&
              pl.uniformNumber === this.selectedPlayer
          ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef,
    @Inject(ENV_HREF) public envHref: string
  ) {
    this.game$.subscribe((game) => {
      this.game = game;
      this.plays = game.plays.slice().reverse();
    });
  }

  shotXPos(team: string, x: number, side: string) {
    if (team === "HomeTeam") {
      if (side === "right") {
        return 100 - (100 - x);
      } else {
        return 100 - x;
      }
    } else {
      if (side === "left") {
        return x;
      } else {
        return 100 - x;
      }
    }
  }

  shotYPos(team: string, y: number, side: string) {
    if (team === "HomeTeam") {
      if (side === "right") {
        return 100 - (100 - y);
      } else {
        return 100 - y;
      }
    } else {
      if (side === "left") {
        return y;
      } else {
        return 100 - y;
      }
    }
  }

  showPlayerStats(player: LiveStatsPlayer, team: LiveStatsGameTeam) {
    this.modalService.openDialog(this.viewRef, {
      title: `${team.name} - ${player.firstName} ${player.lastName} Stats`,
      childComponent: GamePlayerModalComponent,
      data: player,
    });
  }

  setNet() {
    const net = {
      "mask-image": "url(" + this.envHref + "/assets/soccer_net.svg)",
      "-webkit-mask-image": "url(" + this.envHref + "/assets/soccer_net.svg",
    };
    return net;
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
