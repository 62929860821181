import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable()
export class AppService {
  public windowSize$ = new ReplaySubject<{ width: number; height: number }>(1);

  constructor() {
    this.nextSize();
    window.addEventListener("resize", () => this.nextSize());
  }

  private nextSize() {
    this.windowSize$.next({
      width: window.document.documentElement.clientWidth,
      height: window.document.documentElement.clientHeight
    });
  }
}
