import { Component, Input, Inject, OnChanges } from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsLeaders,
} from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-leaders",
  templateUrl: "./leaders.component.html",
  styleUrls: ["./leaders.component.scss"],
})
export class LeadersComponent implements OnChanges {
  @Input() leaders: LiveStatsLeaders;

  selectedCategoryKey: string = null;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {}

  ngOnChanges() {
    if (this.leaders != null && this.selectedCategoryKey == null) {
      this.selectedCategoryKey = this.leaders.categories[0].key;
    }
  }
}
