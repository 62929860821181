import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { LiveStatsGameWrapper, AdsResponse } from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN, ENV_HREF } from "../../app.component";
import { Observable } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit, OnDestroy {
  navigationSubscription;
  hasCoordinates = false;
  gameDateUTCDate: string;
  gameDateUTCTime: string;

  gatewayUrl = "https://mobile-app-gateway-prod.sidearmsports.com/host/";
  windowLocation = window.location.origin;
  hostName = window.location.hostname;

  liveStatsPath = window.location.pathname.split("/");
  sportPath = "";
  sport = "";

  public SA15050Ads: Observable<AdsResponse>;
  public SA300250Ads: Observable<AdsResponse>;
  public SA32050Ads: Observable<AdsResponse>;
  public SA72890Ads: Observable<AdsResponse>;
  public SA97066Ads: Observable<AdsResponse>;

  constructor(
    @Inject(ENV_HREF) public envHref: string,
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private router: Router,
    private http: HttpClient
  ) {
    if (this.liveStatsPath[1] !== "hub") {
      this.sportPath = this.liveStatsPath[2].toLowerCase();
      this.sport = this.sportPath.split(";")[0].toLowerCase();
    }
    this.game$.subscribe((game) => {
      for (let i = 0, j = game.plays.length; i < j; i++) {
        if (game.plays[i].coordinate !== null) {
          this.hasCoordinates = true;
        }
      }
      this.gameDateUTCDate = moment
        .utc(game.game.dateUtc)
        .local()
        .format("M/DD/YYYY");
      this.gameDateUTCTime = moment
        .utc(game.game.dateUtc)
        .local()
        .format("h:mm a");
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.refreshGameData();
      }
    });
  }

  refreshGameData() { }

  ngOnInit() {
    this.SA15050Ads = this.checkAdSpot("livestats-150x50");
    this.SA300250Ads = this.checkAdSpot("livestats-300x250");
    this.SA32050Ads = this.checkAdSpot("livestats-320x50");
    this.SA72890Ads = this.checkAdSpot("livestats-728x90");
    this.SA97066Ads = this.checkAdSpot("livestats-970x66");
  }

  checkAdSpot(location: string): Observable<AdsResponse> {
    return this.http.get<AdsResponse>(
      this.gatewayUrl + this.hostName + "/api/ads",
      {
        params: {
          location: location,
          global_sport_shortname: this.sport
        },
      }
    ).pipe(map((res) => {
      return res;
    })
    );
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
