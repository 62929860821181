import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewContainerRef
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlayer,
  LiveStatsGameTeam
} from "../live-stats-service.service";
import { Observable } from "rxjs";
import { LIVE_GAME_TOKEN } from "../app.component";
import { ModalDialogService } from "ngx-modal-dialog";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";

@Component({
  selector: "app-game-latest-plays",
  templateUrl: "./game-latest-plays.component.html",
  styleUrls: ["./game-latest-plays.component.scss"]
})
export class GameLatestPlaysComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(g => (this.game = g));
  }

  showPlayerStats(player: LiveStatsPlayer, team: LiveStatsGameTeam) {
    this.modalService.openDialog(this.viewRef, {
      title: `${team.name} - ${player.firstName} ${player.lastName} Stats`,
      childComponent: GamePlayerModalComponent,
      data: player
    });
  }

  ngOnInit() {}
}
