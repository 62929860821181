import { Component, OnInit, Input } from "@angular/core";
import { LiveStatsGameLinescore } from "../live-stats-service.service";

@Component({
  selector: "app-game-linescore",
  templateUrl: "./game-linescore.component.html",
  styleUrls: ["./game-linescore.component.scss"]
})
export class GameLinescoreComponent implements OnInit {
  @Input() lineScore: LiveStatsGameLinescore;
  @Input() names: "ids" | "names" = "names";

  constructor() {}

  ngOnInit() {}

  trackBy(item: { label: string }) {
    return item.label;
  }
}
