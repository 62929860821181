import { sendEvent, EventInfo } from "./s-event";

interface ISlotAnalyticsOptions {
  networkId: string;
  slot: googletag.Slot;
  element: string;
  elinstance: string;
}

export function gptSlotRequestAnalytics(options: ISlotAnalyticsOptions) {
  return new ForSlot(options)
    .on("slotRequested", () => ({
      name: "requested"
    }))
    .on(
      "slotRenderEnded",
      (slotRenderEvent: googletag.events.SlotRenderEndedEvent) =>
        slotRenderEvent.isEmpty
          ? {
              name: "unfilled"
            }
          : {
              name: "filled",
              label: [
                options.networkId,
                slotRenderEvent.lineItemId,
                slotRenderEvent.creativeId
              ].join(":")
            }
    )
    .one("impressionViewable", () => ({
      name: "viewable"
    }));
}

class ForSlot {
  constructor(private options: ISlotAnalyticsOptions) {}

  on(
    name: string,
    handler: (ev: googletag.events.Event) => EventPartial
  ): this {
    googletag.pubads().addEventListener(name, ev => {
      if (ev.slot === this.options.slot) {
        this.sendEvent(handler(ev));
      }
    });
    return this;
  }

  one(
    name: string,
    handler: (ev: googletag.events.Event) => EventPartial
  ): this {
    let has = false;
    googletag.pubads().addEventListener(name, ev => {
      if (ev.slot === this.options.slot) {
        if (has) {
          return;
        }
        has = true;
        this.sendEvent(handler(ev));
      }
    });
    return this;
  }

  sendEvent(ev: EventPartial) {
    sendEvent({
      element: this.options.element,
      elinstance: this.options.elinstance,
      category: "google-ads",
      value: 1,
      label: [this.options.networkId].join(":"),
      ...ev
    });
  }
}

type EventPartial = {
  name: string;
} & Partial<EventInfo>;
