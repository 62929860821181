import { Component, OnInit, Inject } from "@angular/core";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { LiveStatsGameWrapper } from "../../live-stats-service.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-team-stats",
  templateUrl: "./team-stats.component.html",
  styleUrls: ["./team-stats.component.scss"],
})
export class TeamStatsComponent implements OnInit {
  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {}

  ngOnInit() {}
}
