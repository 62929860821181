import {
  Component,
  InjectionToken,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import {
  Observable,
  of as ObservableOf,
  ReplaySubject,
  combineLatest,
} from "rxjs";
import {
  LiveStatsService,
  LiveStatsGameWrapper,
  ArchivedGameReference,
  AdsResponse
} from "./live-stats-service.service";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ChildActivationEnd } from "@angular/router";
import {
  filter,
  map,
  shareReplay,
  catchError,
  distinctUntilChanged,
  concat,
} from "rxjs/operators";
import { GameDfpAdsComponent } from "./game-dfp-ads/game-dfp-ads.component";
import { HttpClient } from "@angular/common/http";

export const LIVE_GAME_TOKEN = new InjectionToken<
  Observable<LiveStatsGameWrapper>
>("livestats.game");

export const ENV_HREF = new InjectionToken<string>("livestats.env.href");

const livestatsGame$sub = new ReplaySubject<LiveStatsGameWrapper>(null);
export const livestatsGameFactory = () => livestatsGame$sub.asObservable();

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    { provide: LIVE_GAME_TOKEN, useFactory: livestatsGameFactory },
    { provide: ENV_HREF, useValue: window["env_href"] || "" },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public game$: Observable<LiveStatsGameWrapper>;
  public error$: Observable<any>;
  public archivedGames$: Observable<ArchivedGameReference[]>;
  public routerLinkBase$: Observable<string>;
  public settings: LiveStatsSettings = new LiveStatsSettings();

  private teamColorCss: HTMLStyleElement;
  isMenuActive = false;

  today = Date.now();

  gatewayUrl = "https://mobile-app-gateway-prod.sidearmsports.com/host/";
  windowLocation = window.location.origin;
  hostName = window.location.hostname;

  liveStatsPath = window.location.pathname.split("/");
  school = "";
  sportPath = "";
  sport = "";
  isStickyLeaderboardAdEnabled = true;

  navigationSubscription;

  hasCoordinates = false;

  public footerDismissed = {
    sidearm: false,
    dfp: false,
  };
  public get showHeaderSponsor() {
    return this.sidearmHeaderSponsor || this.dfpHeaderSponsor;
  }
  public sidearmHeaderSponsor = true;
  public dfpHeaderSponsor = true;

  public SA15050Ads: Observable<AdsResponse>;
  public SA300250Ads: Observable<AdsResponse>;
  public SA32050Ads: Observable<AdsResponse>;
  public SA72890Ads: Observable<AdsResponse>;
  public SA97066Ads: Observable<AdsResponse>;

  constructor(
    private liveStats: LiveStatsService,
    private title: Title,
    private router: Router,
    private http: HttpClient,
    @Inject(ENV_HREF) public envHref: string
  ) {
    title.setTitle("SIDEARM Live Stats");
    document
      .getElementById("favicon")
      .setAttribute("href", envHref + "/assets/sidearm_favicon.ico");
    if (this.liveStatsPath[1] !== "hub") {
      this.school = this.liveStatsPath[1].toLowerCase();
      this.sportPath = this.liveStatsPath[2].toLowerCase();
      this.sport = this.sportPath.split(";")[0].toLowerCase();
    }

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (
        e instanceof NavigationEnd &&
        this.router.url.indexOf("summary") > -1
      ) {
        this.refreshGameData();
      }
    });

    const routeParams$ = router.events.pipe(
      filter((e) => e instanceof ChildActivationEnd),
      map((e: ChildActivationEnd) => {
        const params = e.snapshot.params;
        if (params["school"] && params["sport"]) {
          return {
            school: params["school"].toLowerCase() as string,
            sport: params["sport"].toLowerCase() as string,
            game: params["game"] as string,
          };
        }

        return null;
      }),
      filter((ss) => ss != null),
      distinctUntilChanged((a, b) => {
        const areEqual = JSON.stringify(a) === JSON.stringify(b);
        return areEqual;
      }),
      shareReplay()
    );

    const routeParamsForOAS$ = routeParams$.pipe(
      map((params) => ({
        schoolUrlName: params.school,
        school:
          params.school === "sidearmstats" && (<any>window).livestats_foldername
            ? (<any>window).livestats_foldername
            : params.school.toLowerCase(),
        sport: (<any>window).livestats_sport
          ? (<any>window).livestats_sport
          : params.sport.toLowerCase(),
        game: params.game,
      }))
    );

    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => GameDfpAdsComponent.newCorrelator());

    const config$ = liveStats.liveStatsConfigFor(routeParamsForOAS$);

    // TODO: there HAS TO be a less bad way of doing this...
    this.error$ = config$.pipe(
      filter((anything) => false),
      catchError((err) => {
        console.error("catchError", err);
        return Promise.resolve(err);
      })
    );

    this.archivedGames$ = liveStats.archivedGamesFor(config$);
    this.game$ = liveStats
      .liveStatsFor(
        combineLatest(routeParamsForOAS$, config$).pipe(
          map(([routeParams, config]) => ({
            school: routeParams.school,
            sport: routeParams.sport,
            game: routeParams.game,
            hostname: config.hostname,
          })),
          shareReplay()
        )
      )
      .pipe(shareReplay());

    this.game$.subscribe((game) => {
      livestatsGame$sub.next(game);

      const clock = game.game.isComplete
        ? "Final"
        : game.type === "BaseballSoftballGame"
          ? `${game.formatPeriod({
            inning: game.game.situation.inning,
            includePeriodName: true,
          })} ${game.game.situation.outs} Outs`
          : game.type === "VolleyballGame"
            ? `${game.formatPeriod({
              ordinal: true,
              includePeriodName: true,
            })}`
            : `${game.formatClock()} ${game.formatPeriod({
              ordinal: true,
              includePeriodName: true,
            })}`;

      const score =
        game.type === "VolleyballGame"
          ? `${game.visitingTeam.id} (${game.visitingTeam.score})${game.visitingTeam.setScore} ${game.homeTeam.id} (${game.homeTeam.score})${game.homeTeam.setScore}`
          : `${game.visitingTeam.id} ${game.visitingTeam.score} ${game.homeTeam.id} ${game.homeTeam.score}`;

      title.setTitle(`${score} ${clock}`);
    });

    this.setupCssVariables();

    this.checkCoordinates();

    this.routerLinkBase$ = routeParams$.pipe(
      map((ss) => {
        if (ss == null) {
          return "";
        }
        return `${ss.school}/${ss.sport}${ss.game == null ? "" : `;game=${ss.game}`
          }`;
      })
    );

    this.SA15050Ads = this.checkAdSpot("livestats-150x50");
    this.SA300250Ads = this.checkAdSpot("livestats-300x250");
    this.SA32050Ads = this.checkAdSpot("livestats-320x50");
    this.SA72890Ads = this.checkAdSpot("livestats-728x90");
    this.SA97066Ads = this.checkAdSpot("livestats-970x66");

  }

  checkAdSpot(location: string): Observable<AdsResponse> {
    return this.http.get<AdsResponse>(
      this.gatewayUrl + this.hostName + "/api/ads",
      {
        params: {
          location: location,
          global_sport_shortname: this.sport
        },
      }
    ).pipe(map((res) => {
      return res;
    })
    );
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
    if (this.isMenuActive === true) {
      document.getElementsByTagName("body")[0].classList.add("noScroll");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("noScroll");
    }
  }

  backToTop() {
    window.scrollTo(0, 0);
  }

  skipToMainContent() {
    window.location.hash = "#main-content";
    document.getElementById("main-content").scrollIntoView();
    document.getElementById("main-content").focus();
  }

  backToLive() {
    document.getElementsByTagName("body")[0].classList.remove("archivedGame");
  }

  refreshGameData() {
    this.game$.subscribe((game) => {
      livestatsGame$sub.next(game);
    });
  }

  checkCoordinates() {
    this.game$.subscribe((game) => {
      for (let i = 0, j = game.plays.length; i < j; i++) {
        if (game.plays[i].coordinate) {
          this.hasCoordinates = true;
        }
      }
    });
  }

  enableStickyLeaderboardAd() {
    this.isStickyLeaderboardAdEnabled = true;
  }

  disableStickyLeaderboardAd() {
    this.isStickyLeaderboardAdEnabled = false;
  }

  setupCssVariables() {
    this.teamColorCss = document.createElement("style");
    const head = document.getElementsByTagName("head")[0];
    head.insertBefore(this.teamColorCss, head.childNodes[0]);

    ObservableOf({
      home: { base: "#4a4a4a", text: "#efefef" },
      away: { base: "#4a4a4a", text: "#efefef" },
    })
      .pipe(
        concat(
          this.game$.pipe(
            map((game) => ({
              home: {
                base: game.homeTeam.color,
                text: game.homeTeam.textColor,
              },
              away: {
                base: game.visitingTeam.color,
                text: game.visitingTeam.textColor,
              },
            }))
          )
        )
      )
      .subscribe((colors) => {
        this.teamColorCss.innerHTML = `
          :root {
            --home-team-base: ${colors.home.base};
            --home-team-contrast: ${colors.home.text};
            --away-team-base: ${colors.away.base};
            --away-team-contrast: ${colors.away.text};
          }
        `;
      });
  }

  dismissFooter(type: "sidearm" | "dfp") {
    this.footerDismissed[type] = true;
  }
}

export class LiveStatsSettings {
  _selectedTheme: string;
  _fontSize: number;

  set selectedTheme(value: string) {
    this._selectedTheme = value;
    this.toStorage();
  }
  get selectedTheme() {
    return this._selectedTheme;
  }
  set fontSize(value: number) {
    this._fontSize = value;
    this.toStorage();
  }
  get fontSize() {
    return this._fontSize;
  }

  constructor() {
    this._selectedTheme = "light";
    this._fontSize = 16;
    this.fromStorage();
  }

  private fromStorage() {
    const fromStorage = JSON.parse(localStorage["settings"] || "null");
    if (fromStorage == null) {
      return;
    }
    Object.assign(this, fromStorage);
  }
  private toStorage() {
    localStorage["settings"] = JSON.stringify(this);
  }
}
