import { Component, Input, Inject } from "@angular/core";
import {
  LiveStatsPlay,
  LiveStatsGameWrapper,
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MyComponent } from "../../lib/my-component";

@Component({
  selector: "app-plays-list",
  templateUrl: "./plays-list.component.html",
  styleUrls: ["./plays-list.component.scss"],
})
export class PlaysListComponent extends MyComponent {
  @Input() plays: LiveStatsPlay[];

  game: LiveStatsGameWrapper;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    super();
    game$.pipe(takeUntil(this.unsub)).subscribe((g) => (this.game = g));
  }

  playClockText(play: LiveStatsPlay): string {
    return `${this.game.formatClock({
      clockSeconds: play.clockSeconds,
    })} ${this.game.formatPeriod({
      period: play.period,
      ordinal: true,
    })}`;
  }
}
