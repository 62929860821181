import { Component, OnInit, Input } from "@angular/core";
import {
  LiveStatsGameWrapper
} from "../live-stats-service.service";
import * as moment from "moment";

@Component({
  selector: "app-game-details",
  templateUrl: "./game-details.component.html",
  styleUrls: ["./game-details.component.scss"]
})
export class GameDetailsComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  @Input() layoutType: string;
  gameDateUTCDate: string;
  gameDateUTCTime: string;

  constructor() {}

  ngOnInit() {
    this.gameDateUTCDate = moment.utc(this.game.game.dateUtc).local().format("M/DD/YYYY");
    this.gameDateUTCTime = moment.utc(this.game.game.dateUtc).local().format("h:mm a");
  }
}
