import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  LiveStatsStatsGrid,
  LiveStatsCompare,
  LiveStatsGameWrapper
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-stat-comparison",
  templateUrl: "./stat-comparison.component.html",
  styleUrls: ["./stat-comparison.component.scss"]
})
export class StatComparisonComponent implements OnInit {
  @Input() labelA: string;
  @Input() labelB: string;
  @Input() statsA: LiveStatsStatsGrid;
  @Input() statsB: LiveStatsStatsGrid;
  @Input() layoutType: string;
  game: LiveStatsGameWrapper;

  compare$: Observable<LiveStatsCompare[]>;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    this.game$.subscribe(g => (this.game = g));

    this.compare$ = this.game$.pipe(
      map(game => {
        const set = new Set(
          game.compare(this.statsA, this.statsB,
          {excludeKeys: ['involvedInDoublePlays', 'involvedInTriplePlays', 'walksAllowed',
          'pitchingStrikeouts', 'pitchingRuns', 'hitsAllowed'
          ]})
        );
        return Array.from(set);
      })
    );
  }

  ngOnInit() {}

  trackStatCompare(stat: LiveStatsCompare) {
    return stat.key;
  }
}
