import { Injectable, NgZone } from "@angular/core";
import { Observable,  combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";

@Injectable()
export class LiveHubService {
  liveGamesToday$: Observable<LiveHubGameViewModel[]>;
  constructor(private http: HttpClient, private zone: NgZone) {
    this.liveGamesToday$ = combineLatest(
      this.http.get<{ data: LiveHubGame[] }>(
        "https://aggregation-service.sidearmsports.com/services/games.ashx",
        {
          params: {
            livestats: "1",
            start_date: moment()
              .startOf("day")
              .add(3, "hours")
              .utc()
              .toISOString(),
            end_date: moment()
              .endOf("day")
              .utc()
              .add(3, "hours")
              .toISOString()
          }
        }
      )
    ).pipe(
      map(([{ data: listOfGames }]) =>
        listOfGames
          .map(game => {
            return {
              ...game,
              date_info: {
                ...game.date_info,
                formatted_time: game.date_info.all_day
                  ? "All Day"
                  : game.date_info.tbd
                  ? "TBD"
                  : moment
                      .utc(game.date_info.datetime_utc)
                      .local()
                      .format("h:mm a")
              },
              division: {
                ...game.division,
                name: game.division && game.division.name ? game.division.name : ''
              },
              home_team: {
                ...game.home_team,
                sort_name: toSortName(game.home_team.name),
                conference_name:
                  game.home_team.conference && game.home_team.conference.name
              },
              away_team: {
                ...game.away_team,
                sort_name: game.away_team && toSortName(game.away_team.name),
                conference_name:
                  game.away_team.conference && game.away_team.conference.name
              }
            };
          })
          .sort(
            sorter<LiveHubGameViewModel>(
              [
                game => game.date_info.datetime_utc,
                game => game.sport.abbrev,
                game => game.division.name
              ],
              "asc"
            )
          )
      ),
      shareReplay()
    );
  }
}

function toSortName(name: string): string {
  return (name + "").replace("University of", "").trim();
}

export function sorter<T, TS = any>(
  callbacks: Array<(item: T) => TS>,
  direction: "asc" | "desc"
) {
  return (a: T, b: T) => {
    const dir = direction === "asc" ? 1 : -1;
    for (const callback of callbacks) {
      const sortValA = callback(a);
      const sortValB = callback(b);
      if (sortValA > sortValB) {
        return 1 * dir;
      }
      if (sortValA < sortValB) {
        return -1 * dir;
      }
    }
    return 0;
  };
}

export interface LiveHubGameViewModel extends LiveHubGame {
  date_info: LiveHubDateInfo & { formatted_time: string };
  away_team: LiveHubTeamViewModel;
  home_team: LiveHubTeamViewModel;
}

export interface LiveHubTeamViewModel extends LiveHubTeam {
  conference_name: string;
  sort_name: string;
}

export interface LiveHubGame {
  client_id: number;
  id: number;
  division: LiveHubDivision;
  date_info: LiveHubDateInfo;
  home_team: LiveHubTeam;
  away_team: LiveHubTeam;
  media: {
    audio: string;
    radio: string;
    video: string;
    livestats: string;
    livestats_feed: string;
  };
  sport: LiveHubSport;
  location: {
    location: string;
    han: "H" | "A" | "N";
    facility: string;
  };
  isToggled: boolean;
}

export interface LiveHubTeam {
  school_global_id: number;
  name: string;
  logo: string;
  website: string;
  conference: LiveHubConference;
}

export interface LiveHubDivision {
  id: number;
  name: string;
}

export interface LiveHubConference {
  id: number;
  name: string;
}

export interface LiveHubDateInfo {
  tbd: boolean;
  all_day: false;
  datetime_utc: string;
  date: string;
  time: string;
}

export interface LiveHubSport {
  id: number;
  title: string;
  shortname: string;
  abbrev: string;
  global_sport_id: number;
}
