import { Component, OnInit, Inject } from "@angular/core";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { LiveStatsGameWrapper } from "../../live-stats-service.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-individual",
  templateUrl: "./individual.component.html",
  styleUrls: ["./individual.component.scss"]
})
export class IndividualComponent implements OnInit {
  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {}

  ngOnInit() {}
}
