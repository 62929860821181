import {
  Component,
  OnInit,
  Input,
  HostListener
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsFootballDrives
} from "../live-stats-service.service";

@Component({
  selector: "app-game-drives",
  templateUrl: "./game-drives.component.html",
  styleUrls: ["./game-drives.component.scss"]
})
export class GameDrivesComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];
  private _drives: LiveStatsFootballDrives[] = [];

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays.slice().reverse();
    if (this._game.type === "FootballGame") {
      this._drives = value.footballGame.drives.slice().reverse();
    }
  }
  get game() {
    return this._game;
  }

  @Input() layoutType: string;
  @Input() team: string;
  highlightedRow: number = null;

  @HostListener("mouseleave")
  onMouseLeave() {
    this.highlightedRow = null;
  }
  onMouseEnter(index: number) {
    this.highlightedRow = index;
  }

  get scoringPlays(): LiveStatsPlay[] {
    return this._plays
      .filter(p => {
        if (p.score === null) {
          return false;
        }
        return true;
      })
      .reverse();
  }

  get gameDrives(): LiveStatsFootballDrives[] {
    return this._drives
      .filter(p => {
        if (this.team) {
          if (p.team === this.team) {
            return true;
          }
          return false;
        }
        return true;
      })
      .reverse();
  }

  constructor() {}

  ngOnInit() {}
}
