import { Component, OnInit, Input } from "@angular/core";
import { range } from "lodash";
import {
  LiveStatsGameWrapper
} from "../live-stats-service.service";

@Component({
  selector: "app-game-baseball-count",
  templateUrl: "./game-baseball-count.component.html",
  styleUrls: ["./game-baseball-count.component.scss"]
})
export class GameBaseballCountComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  constructor() {}

  get currentBalls() {
    return range(3).map(count => this.game.game.situation.balls > count);
  }

  get currentStrikes() {
    return range(2).map(count => this.game.game.situation.strikes > count);
  }

  get currentOuts() {
    return range(2).map(count => this.game.game.situation.outs > count);
  }

  ngOnInit() {}
}
