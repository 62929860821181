import {
  Component,
  OnInit,
  Inject,
  Input
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsFootballDrives
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";

@Component({
  selector: 'app-game-drives-chart',
  templateUrl: './game-drives-chart.component.html',
  styleUrls: ['./game-drives-chart.component.scss']
})
export class GameDrivesChartComponent implements OnInit {
  private _game: LiveStatsGameWrapper;
  private _plays: LiveStatsPlay[] = [];
  private _drives: LiveStatsFootballDrives[] = [];
  @Input() layoutType: string;

  @Input()
  set game(value: LiveStatsGameWrapper) {
    this._game = value;
    this._plays = value.plays.slice().reverse();
    if (this._game.type === "FootballGame") {
      this._drives = value.footballGame.drives.slice().reverse();
    }
  }
  get game() {
    return this._game;
  }

  get gameDrives(): LiveStatsFootballDrives[] {
    return this._drives
      .filter(p => {
        return true;
      })
      .reverse();
  }

  get scoringPlays(): LiveStatsPlay[] {
    return this._plays
      .filter(p => {
        if (p.score === null) {
          return false;
        }
        return true;
      });
  }

  constructor(@Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>) {
    this.game$.subscribe(game => {
      this.game = game;
    });
  }

  startSpot(drive) {
    const driveStartSpot = parseInt(drive.startSpot.replace(/\D/g, ''), 10);
    return driveStartSpot;
  }

  endSpot(drive) {
    const driveEndSpot = parseInt(drive.endSpot.replace(/\D/g, ''), 10);
    return driveEndSpot;
  }

  endSide(drive) {
    const driveEndSide = drive.endSpot.replace(/[0-9]/g, '');
    return driveEndSide;
  }

  getYards(drive) {
    const driveStartSide = drive.startSpot.replace(/[0-9]/g, '');
    const driveStartSpot = parseInt(drive.startSpot.replace(/\D/g, ''), 10);
    const driveEndSpot = parseInt(drive.endSpot.replace(/\D/g, ''), 10);
    let yards;
    if (drive.yards === 0 && drive.plays === 0 && (drive.endHow === 'TD' || drive.endHow === 'FG')) {
      if (drive.team === 'VisitingTeam') {
        if (driveStartSide === this.game.visitingTeam.id) {
          yards = 100 - driveStartSpot;
        } else {
          yards = driveStartSpot;
        }
      } else {
        if (driveStartSide === this.game.visitingTeam.id) {
          yards = driveStartSpot;
        } else {
          yards = 100 - driveStartSpot;
        }
      }
    } else {
      yards = drive.yards;
    }
    return yards;
  }

  setDrive(drive) {
    const driveStartSide = drive.startSpot.replace(/[0-9]/g, '');
    const driveStartSpot = parseInt(drive.startSpot.replace(/\D/g, ''), 10);
    const driveEndSpot = parseInt(drive.endSpot.replace(/\D/g, ''), 10);
    let driveStyles;

    if (drive.team === 'VisitingTeam') {
      if (drive.yards >= 0) {
        if (driveStartSide === this.game.visitingTeam.id) {
          if (drive.endHow === "TD" &&
          (drive.startHow === "FUMB" ||
          drive.startHow === "INT" ||
          drive.startHow === "KO" ||
          drive.startHow === "PUNT" ||
          drive.startHow === "FGA") &&
          drive.plays === 0) {
            // 0 play scoring scenario score
            driveStyles = {
              'width': 100 - driveStartSpot + "%",
              'margin-left': driveStartSpot + "%"
            };
          } else {
            driveStyles = {
              'width': drive.yards + "%",
              'margin-left': driveStartSpot + "%"
            };
          }
        } else {
          if (drive.endHow === "TD" &&
          (drive.startHow === "FUMB" ||
          drive.startHow === "INT" ||
          drive.startHow === "KO" ||
          drive.startHow === "PUNT" ||
          drive.startHow === "FGA") &&
          drive.plays === 0) {
            // 0 play scoring scenario score
            driveStyles = {
              'width': driveStartSpot + "%",
              'margin-left': 100 - driveStartSpot + "%"
            };
          } else {
            driveStyles = {
              'width': drive.yards + "%",
              'margin-left': 100 - driveStartSpot + "%"
            };
          }
        }
      } else {
        driveStyles = {
          'width': -drive.yards + "%",
          'margin-left': (driveStartSide === this.game.visitingTeam.id)
          ? driveStartSpot - (-drive.yards) + "%"
          : 100 - driveStartSpot - (-drive.yards) + "%"
        };
      }
    }

    if (drive.team === 'HomeTeam') {
      if (drive.yards >= 0) {
        if (driveStartSide === this.game.visitingTeam.id) {
          if (drive.endHow === "TD" &&
          (drive.startHow === "FUMB" ||
          drive.startHow === "INT" ||
          drive.startHow === "KO" ||
          drive.startHow === "PUNT" ||
          drive.startHow === "FGA") &&
          drive.plays === 0) {
            // 0 play scoring scenario score
            driveStyles = {
              'width': driveStartSpot + "%",
              'margin-left': "0%",
              'margin-right': 100 - driveStartSpot + "%"
            };
          } else {
            driveStyles = {
              'width': drive.yards + "%",
              'margin-left': (driveStartSpot - drive.yards) + "%",
              'margin-right': 100 - driveStartSpot + "%"
            };
          }
        } else {
          if (drive.endHow === "TD" &&
          (drive.startHow === "FUMB" ||
          drive.startHow === "INT" ||
          drive.startHow === "KO" ||
          drive.startHow === "PUNT" ||
          drive.startHow === "FGA") &&
          drive.plays === 0) {
            // 0 play scoring scenario score
            driveStyles = {
              'width': driveStartSpot + "%",
              'margin-left': "0%",
              'margin-right': driveStartSpot - drive.yards + "%"
            };
          } else {
            driveStyles = {
              'width': drive.yards + "%",
              'margin-left': 100 - (driveStartSpot + drive.yards) + "%",
              'margin-right': driveStartSpot + "%"
            };
          }
        }
      } else {
        driveStyles = {
          'width': -drive.yards + "%",
          'margin-left': (driveStartSide === this.game.visitingTeam.id)
          ? driveStartSpot - (drive.yards) + "%"
          : 100 - driveStartSpot + "%",
          'margin-right': (driveStartSide === this.game.visitingTeam.id)
          ? 100 - (driveStartSpot - drive.yards) + "%"
          : (driveStartSpot + drive.yards) + "%"
        };
      }
    }

    return driveStyles;
  }

  ngOnInit() {
  }

}
