import {
  Component,
  OnInit,
  Inject,
  HostBinding,
  ViewContainerRef,
  HostListener,
} from "@angular/core";
import {
  LiveStatsPlayerStatsGridPlayer,
  LiveStatsGameWrapper,
  LiveStatsStats,
  LiveStatsPlay,
  TeamCamelKey,
  LiveStatsPlayer,
  withLastScore,
} from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable } from "rxjs";
import { map, first } from "rxjs/operators";
import { flatten, range, flatMap } from "lodash";
import { ModalDialogService } from "ngx-modal-dialog";
import { RunSummaryComponent } from "../../run-summary/run-summary.component";
import { GamePlayerModalComponent } from "../../game-player-modal/game-player-modal.component";

interface Lasts {
  fg: { time: string; text: string };
  lastLead: { time: string; text: string };
  largeLead: { time: string; text: string };
  leadsTime: { time: string; text: string };
}

interface Trend {
  team: string;
  teamId: string;
  name: string;
  trends: MicroTrend[];
}
interface MicroTrend {
  text: string;
  qualifier: string;
  positiveNegative: MicroTrendType;
  plays: LiveStatsPlay[];
}

enum MicroTrendType {
  positive = "positive",
  negative = "negative",
}

@Component({
  selector: "app-media-box",
  templateUrl: "./media-box.component.html",
  styleUrls: ["./media-box.component.scss"],
})
export class MediaBoxComponent implements OnInit {
  @HostBinding("class") class = "high-contrast-text";
  game: LiveStatsGameWrapper;
  innerWidth: number;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe((g) => (this.game = g));
  }

  lasts$: Observable<{ [team: string]: Lasts }> = this.game$.pipe(
    map((game) => {
      const agg: { [team: string]: Lasts } = {};

      ["VisitingTeam", "HomeTeam"].forEach((key) => {
        const camelKey: TeamCamelKey = <TeamCamelKey>(
          (key[0].toLowerCase() + key.substring(1))
        );
        const otherCamelKey: TeamCamelKey =
          camelKey === "visitingTeam" ? "homeTeam" : "visitingTeam";

        const plays = withLastScore(game.plays).reverse();
        const scoringPlays = plays.filter((p) => p.score != null);

        const lastFg = scoringPlays.find(
          (p) => p.type !== "FT" && p.action === "GOOD" && p.team === key
        );

        const largeLead = scoringPlays
          .filter((p) => p.score[camelKey] > p.score[otherCamelKey])
          .sort((a, b) => {
            const usA = a.score[camelKey];
            const themA = a.score[otherCamelKey];
            const usB = b.score[camelKey];
            const themB = b.score[otherCamelKey];

            const leadA = usA - themA;
            const leadB = usB - themB;

            return leadB - leadA;
          })[0];

        const lastLead = scoringPlays.filter((play) => {
          const us = play.score[camelKey];
          const them = play.score[otherCamelKey];

          return us > them;
        })[0];

        const leadsTime = ``;

        const leadsText = `
        Leads: ${game.stats[camelKey].totals.byKey("leads").value} /
        ${game.stats[camelKey].totals.byKey("timeWithLead").value}
        (${game.stats[camelKey].totals.byKey("percentLead").value}%) /
        Ties: ${game.stats[camelKey].totals.byKey("ties").value}`;

        agg[camelKey] = {
          fg: {
            time: (lastFg && this.playClockText(lastFg)) || "-",
            text: (lastFg && this.playText(lastFg)) || "-",
          },
          largeLead: {
            time: (largeLead && this.playClockText(largeLead)) || "-",
            text:
              (largeLead &&
                this.playScore(largeLead, camelKey, otherCamelKey) +
                  ` (${
                    largeLead.score[camelKey] - largeLead.score[otherCamelKey]
                  } pts)`) ||
              "-",
          },
          lastLead: {
            time: (lastLead && this.playClockText(lastLead)) || "-",
            text:
              (lastLead && this.playScore(lastLead, camelKey, otherCamelKey)) ||
              "-",
          },
          leadsTime: {
            time: " ",
            text: leadsText,
          },
        };
      });

      return agg;
    })
  );

  trends$: Observable<Trend[]> = this.game$.pipe(
    map((game) => {
      const plays = game.plays.slice().reverse();

      const trends: Trend[] = flatten([
        this.teamTrends(game, (team) => this.teamTeamTrends(team, game, plays)),
        this.teamTrends(game, (team) =>
          this.teamPlayerTrends(team, game, plays)
        ),
      ]);

      return trends;
    })
  );

  shootingTrends = [
    {
      name: "FG",
      types: ["JUMPER", "DUNK", "LAYUP", "3PTR", "TIPIN"],
    },
    { name: "3PTR", types: ["3PTR"] },
  ];

  showTrend(trend: Trend, microTrend: MicroTrend) {
    const run = this.game.calculateRun(microTrend.plays.slice().reverse());
    this.modalService.openDialog(this.viewRef, {
      title: `${trend.teamId} ${trend.name} ${microTrend.text} ${microTrend.qualifier}`,
      childComponent: RunSummaryComponent,
      data: run,
    });
  }

  teamTrends(
    game: LiveStatsGameWrapper,
    finder: (t: LiveStatsStats) => Trend[]
  ) {
    return flatMap([game.stats.homeTeam, game.stats.visitingTeam], (t) =>
      finder(t)
    );
  }

  teamTeamTrends(
    team: LiveStatsStats,
    game: LiveStatsGameWrapper,
    plays: LiveStatsPlay[]
  ): Trend[] {
    const teamName = team === game.stats.homeTeam ? "HomeTeam" : "VisitingTeam";
    const otherTeamName =
      team === game.stats.homeTeam ? "HomeTeam" : "VisitingTeam";
    const teamNameCamel =
      team === game.stats.homeTeam ? "homeTeam" : "visitingTeam";
    const otherTeamNameCamel =
      team === game.stats.homeTeam ? "visitingTeam" : "homeTeam";
    const scoresFromStart = plays
      .filter((p) => p.score != null)
      .slice()
      .reverse();

    const unbrokenRunTrend = scoresFromStart
      .map((play) => {
        const diff = {
          us: game.game[teamNameCamel].score - play.score[teamNameCamel],
          them:
            game.game[otherTeamNameCamel].score -
            play.score[otherTeamNameCamel],
        };
        const { us, them } = diff;

        const fromNow =
          this.game.calculateTimeFromStart() -
          this.game.calculateTimeFromStart(play);

        const allPlaysInTime = plays.slice(
          plays.findIndex((p) => p.id === scoresFromStart.slice(-1)[0].id),
          plays.findIndex((p) => p.id === play.id) + 1
        );

        let scoringPlays = [];

        scoringPlays = allPlaysInTime.filter((p) => {
          if (p.action === "GOOD" && p.team === teamName) {
            return true;
          }
        });

        if (us >= 5 && them === 0) {
          return {
            us,
            text: `${us}-${them} Run`,
            qualifier: this.game.formatClock({
              clockSeconds:
                fromNow > 0
                  ? fromNow
                  : this.game.calculateTimeFromStart(
                      scoresFromStart.slice(-1)[0]
                    ) - this.game.calculateTimeFromStart(play),
            }),
            positiveNegative: MicroTrendType.positive,
            plays: scoringPlays,
          };
        }
      })
      .find((trend) => trend != null);

    const brokenRunTrend: MicroTrend = scoresFromStart
      .map((play) => {
        const diff = {
          us: game.game[teamNameCamel].score - play.score[teamNameCamel],
          them:
            game.game[otherTeamNameCamel].score -
            play.score[otherTeamNameCamel],
        };
        const { us, them } = diff;

        const fromNow =
          this.game.calculateTimeFromStart() -
          this.game.calculateTimeFromStart(play);

        const allPlaysInTime = plays.slice(
          plays.findIndex((p) => p.id === scoresFromStart.slice(-1)[0].id),
          plays.findIndex((p) => p.id === play.id)
        );

        let scoringPlays = [];

        scoringPlays = allPlaysInTime.filter((p) => {
          if (p.action === "GOOD") {
            return true;
          }
        });

        if (unbrokenRunTrend != null && unbrokenRunTrend.us === us) {
          return null;
        }
        if (them > 0 && us / them > 4) {
          return {
            text: `${us}-${them} Run`,
            qualifier: this.game.formatClock({
              clockSeconds:
                fromNow > 0
                  ? fromNow
                  : this.game.calculateTimeFromStart(
                      scoresFromStart.slice(-1)[0]
                    ) - this.game.calculateTimeFromStart(play),
            }),
            positiveNegative: MicroTrendType.positive,
            plays: scoringPlays,
          };
        }
      })
      .find((trend) => trend != null);

    const scorelessTrend: MicroTrend = scoresFromStart
      .map((play) => {
        const diff = {
          us: game.game[teamNameCamel].score - play.score[teamNameCamel],
        };
        const { us } = diff;

        const fromNow =
          this.game.calculateTimeFromStart() -
          this.game.calculateTimeFromStart(play);

        if (us === 0 && fromNow > 90) {
          let lastScore = [];

          lastScore = scoresFromStart.filter((p) => {
            if (p.team === teamName) {
              return true;
            }
          });

          let playAfterScore;
          playAfterScore =
            game.plays[game.plays.indexOf(lastScore[lastScore.length - 1])];

          const allPlaysInTime = plays.slice(
            plays.findIndex(
              (p) => p.id === game.plays[game.plays.length - 1].id
            ),
            plays.findIndex((p) => p.id === playAfterScore.id)
          );

          return {
            text: `Scoreless`,
            qualifier: this.game.formatClock({
              clockSeconds: fromNow,
            }),
            positiveNegative: MicroTrendType.negative,
            plays: allPlaysInTime,
          };
        }
      })
      .find((trend) => trend != null);

    const shootingTrends: MicroTrend[] = this.shootingTrends.map((types) => {
      const playsOfType = plays
        .filter((p) => p.team === teamName)
        .filter((p) => types.types.includes(p.type));
      // shooting trends
      return range(playsOfType.length)
        .map((i) => {
          const selectedWindowPlays = playsOfType.slice(
            0,
            playsOfType.length - i
          );
          const good = selectedWindowPlays.filter((p) => p.action === "GOOD")
            .length;
          const miss = selectedWindowPlays.filter((p) => p.action !== "GOOD")
            .length;

          if ((good >= 3 && miss <= 1) || (miss >= 3 && good <= 1)) {
            return {
              text: `${types.name} ${good}-${miss + good}`,
              qualifier:
                playsOfType.length === selectedWindowPlays.length
                  ? ``
                  : `Last ${selectedWindowPlays.length}`,
              positiveNegative:
                good >= 3 && miss <= 1
                  ? MicroTrendType.positive
                  : MicroTrendType.negative,
              plays: selectedWindowPlays,
            };
          }
          return null;
        })
        .find((trend) => trend != null);
    });

    const aggregateTrends = []
      .concat([unbrokenRunTrend])
      .concat([scorelessTrend])
      .concat([brokenRunTrend])
      .concat(shootingTrends)
      .filter((trend) => trend != null);

    if (aggregateTrends.length > 0) {
      return [
        {
          team: teamName,
          teamId: game.getTeam(teamName).id,
          name: `TEAM`,
          trends: aggregateTrends,
        },
      ];
    } else {
      return [];
    }
  }

  teamPlayerTrends(
    t: LiveStatsStats,
    game: LiveStatsGameWrapper,
    plays: LiveStatsPlay[]
  ): Trend[] {
    return flatMap(
      t.players.map((p) => {
        return {
          team: t === game.stats.homeTeam ? "HomeTeam" : "VisitingTeam",
          player: p,
        };
      }),
      (player) => {
        return this.teamPlayerPlayerTrends(
          player.player,
          t,
          game,
          plays.filter(
            (play) =>
              play.team === player.team &&
              play.player &&
              play.player.uniformNumber === player.player.uniformNumber
          )
        );
      }
    );
  }

  teamPlayerPlayerTrends(
    player: LiveStatsPlayer,
    t: LiveStatsStats,
    game: LiveStatsGameWrapper,
    plays: LiveStatsPlay[]
  ): Trend[] {
    const playerTrends = this.shootingTrends
      .map((types) => {
        const playsOfType = plays.filter((p) => types.types.includes(p.type));
        return range(playsOfType.length)
          .map((i) => {
            const selectedWindowPlays = playsOfType.slice(
              0,
              playsOfType.length - i
            );
            const good = selectedWindowPlays.filter((p) => p.action === "GOOD")
              .length;
            const miss = selectedWindowPlays.filter((p) => p.action !== "GOOD")
              .length;

            if (good >= 3 && miss <= 1) {
              return {
                text: `${types.name} ${good}-${miss + good}`,
                qualifier:
                  playsOfType.length === selectedWindowPlays.length
                    ? ``
                    : `Last ${selectedWindowPlays.length}`,
                positiveNegative: MicroTrendType.positive,
                plays: selectedWindowPlays,
              };
            }
            return null;
          })
          .find((trend) => trend != null);
      })
      .filter((trend) => trend != null);
    if (playerTrends.length > 0) {
      return [
        {
          team: player.team,
          teamId: game.getTeam(player.team).id,
          name: `${player.firstName} ${player.lastName}`,
          trends: playerTrends,
        },
      ];
    } else {
      return [];
    }
  }

  playClockText(play: LiveStatsPlay): string {
    return `${this.game.formatClock({
      clockSeconds: play.clockSeconds,
    })} ${this.game.formatPeriod({
      period: play.period,
      ordinal: true,
    })}`;
  }

  playText(play: LiveStatsPlay): string {
    return `${play.narrative}`;
  }

  playScore(play: LiveStatsPlay, thisTeam: string, thatTeam: string): string {
    return `${play.score[thisTeam]}-${play.score[thatTeam]}`;
  }

  showPlayerModal(player: LiveStatsPlayerStatsGridPlayer) {
    this.game$.pipe(first()).subscribe((game) => {
      const { player: foundPlayer, team } = game.findPlayerFromIndividiualStats(
        player
      );

      this.modalService.openDialog(this.viewRef, {
        title: `${team.name} - ${foundPlayer.firstName} ${foundPlayer.lastName} Stats`,
        childComponent: GamePlayerModalComponent,
        data: foundPlayer,
      });
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }
}
