import {
  Component,
  Input,
  Inject,
  ViewContainerRef,
  OnChanges,
} from "@angular/core";
import {
  LiveStatsPlayer,
  LiveStatsGameTeam,
  LiveStatsLeaders,
  LiveStatsGameWrapper,
} from "../live-stats-service.service";
import { Observable } from "rxjs";
import { LIVE_GAME_TOKEN } from "../app.component";
import { map } from "rxjs/operators";
import { ModalDialogService } from "ngx-modal-dialog";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";

@Component({
  selector: "app-game-team-leaders",
  templateUrl: "./game-team-leaders.component.html",
  styleUrls: ["./game-team-leaders.component.scss"],
})
export class GameTeamLeadersComponent implements OnChanges {
  @Input() leaders: LiveStatsLeaders;
  @Input() chosenCategory: string = null;
  @Input() count: number;
  @Input() layoutType: string;
  game: LiveStatsGameWrapper;

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe((g) => (this.game = g));
  }

  selectedTab = "tab-1";
  setSelected(tab) {
    this.selectedTab = tab;
  }

  showPlayerStats(player: LiveStatsPlayer, team: LiveStatsGameTeam) {
    this.modalService.openDialog(this.viewRef, {
      title: `${team.name} - ${player.firstName} ${player.lastName} Stats`,
      childComponent: GamePlayerModalComponent,
      data: player,
    });
  }

  ngOnChanges() {
    if (
      this.leaders != null &&
      this.chosenCategory == null &&
      this.leaders.categories.length > 0
    ) {
      this.chosenCategory = this.leaders.categories[0].key;
    }
  }
}
