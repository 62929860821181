export function sendEvent(...events: EventInfo[]) {
  if (!("sEvent" in window)) {
    console.warn(`sEvent is not defined`, { events });
    return;
  }

  (window as any).sEvent.push(...events);
}

export interface EventInfo {
  element?: string;
  elinstance?: string;
  category: string;
  name: string;
  label: string;
  value: number;
}
