import { Component, OnInit, Inject } from "@angular/core";
import { LiveStatsGameWrapper } from "../../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../../app.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-drive-summaries",
  templateUrl: "./drive-summaries.component.html",
  styleUrls: ["./drive-summaries.component.scss"],
})
export class DriveSummariesComponent implements OnInit {
  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {}

  ngOnInit() {}
}
