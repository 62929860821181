import { Component, OnInit, Input } from "@angular/core";
import { LiveStatsGameWrapper } from "../live-stats-service.service";

@Component({
  selector: "app-main-details-header",
  templateUrl: "./main-details-header.component.html",
  styleUrls: ["./main-details-header.component.scss"],
})
export class MainDetailsHeaderComponent implements OnInit {
  @Input() game: LiveStatsGameWrapper;
  constructor() {}

  ngOnInit() {}
}
