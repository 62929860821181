import { Component, OnInit, ComponentRef, Inject } from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlayer,
  LiveStatsGameTeam,
  LiveStatsPlayerStats
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { IModalDialog, IModalDialogOptions } from "ngx-modal-dialog";

@Component({
  selector: "app-game-player-modal",
  templateUrl: "./game-player-modal.component.html",
  styleUrls: ["./game-player-modal.component.scss"]
})
export class GamePlayerModalComponent implements OnInit, IModalDialog {
  player: LiveStatsPlayer;
  team: LiveStatsGameTeam;
  game: LiveStatsGameWrapper;
  selectedCategory: string;
  playerStats: { group: string; title: string; stats: LiveStatsPlayerStats }[];
  playerSeasonStats: { group: string; title: string; stats: LiveStatsPlayerStats }[];

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    this.game$.subscribe(g => (this.game = g));
  }

  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<LiveStatsPlayer>>
  ) {
    this.player = options.data;
    this.playerStats = this.game.getPlayerStatsArray(this.player);
    if (this.game.seasonStats.homeTeam !== null || this.game.seasonStats.visitingTeam !== null) {
      this.playerSeasonStats = this.game.getPlayerSeasonStatsArray(this.player);
    }
    const firstCategory = this.playerStats[0];
    this.selectedCategory = firstCategory ? firstCategory.group : null;
  }

  ngOnInit() {}
}
