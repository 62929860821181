import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewContainerRef,
  ViewChild,
  ElementRef,
  OnChanges,
  HostListener,
  OnDestroy
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  LiveStatsPlay,
  LiveStatsPlayer,
  LiveStatsGameTeam,
  TeamPascalKey
} from "../live-stats-service.service";
import { LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { ModalDialogService } from "ngx-modal-dialog";
import { GamePlayerModalComponent } from "../game-player-modal/game-player-modal.component";
@Component({
  selector: 'app-game-basketball-court',
  templateUrl: './game-basketball-court.component.html',
  styleUrls: ['./game-basketball-court.component.scss']
})
export class GameBasketballCourtComponent implements OnInit, OnChanges, OnDestroy {
  game: LiveStatsGameWrapper;
  plays: LiveStatsPlay[] = [];
  drawSubscription;

  @Input()
  selectedTeam: TeamPascalKey;
  @Input()
  selectedPlayer: string;
  @Input()
  type: string;
  @Input()
  action: string;
  @Input()
  selectedPeriods: number[];
  @Input()
  showShotChart: boolean;
  @Input()
  showHeatMap: boolean;

  @ViewChild('heatMap', {static: true}) heatMap: ElementRef;
  public ctx: CanvasRenderingContext2D;

  public canvasEl;
  public ctxParent;
  public ctxWidth;
  public ctxHeight;

  get filteredPlays(): LiveStatsPlay[] {
    return this.plays.filter(p => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (this.selectedPeriods.length > 0 && !this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (!this.type.includes(p.type)) {
        return false;
      }
      if (p.type === 'DUNK' || p.type === 'LAYUP'){
        return false;
      }
      if (!this.action.includes(p.action)) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player]
            .find(
              pl =>
                pl.team === this.selectedTeam &&
                pl.uniformNumber === this.selectedPlayer
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get dunkPlays(): LiveStatsPlay[] {
    return this.plays.filter(p => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (!this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (p.type !== 'DUNK') {
        return false;
      }
      if (!this.action.includes(p.action)) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player]
            .find(
              pl =>
                pl.team === this.selectedTeam &&
                pl.uniformNumber === this.selectedPlayer
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  get layupPlays(): LiveStatsPlay[] {
    return this.plays.filter(p => {
      if (
        this.selectedTeam &&
        !this.selectedPlayer &&
        p.team != null &&
        !(
          this.game.getTeam(p.team).id ===
          this.game.getTeam(this.selectedTeam).id
        )
      ) {
        return false;
      }
      if (!this.selectedPeriods.includes(p.period)) {
        return false;
      }
      if (p.type !== 'LAYUP') {
          return false;
      }
      if (!this.action.includes(p.action)) {
        return false;
      }
      if (
        this.selectedTeam &&
        this.selectedPlayer &&
        (p.player == null ||
          [p.player]
            .find(
              pl =>
                pl.team === this.selectedTeam &&
                pl.uniformNumber === this.selectedPlayer
            ) == null)
      ) {
        return false;
      }
      return true;
    });
  }

  @HostListener('window:resize') onResize() {
    this.canvasEl = this.heatMap.nativeElement;
    this.ctxParent = this.heatMap.nativeElement.parentNode;
    this.ctxWidth = this.ctxParent.clientWidth;
    this.ctxHeight = this.ctxParent.clientHeight;
    this.draw();
  }

  constructor(
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {
    this.game$.subscribe(game => {
      this.game = game;
      this.plays = game.plays.slice().reverse();
    });
    this.drawSubscription = this.game$.subscribe((e: any) => {
      if (this.heatMap) {
        this.draw();
      }
    });
  }

  shotXPos(team: string, x: number, side: string) {
    if (team === 'HomeTeam') {
      if (side === 'right') {
        return 100 - (100 - x);
      } else {
        return 100 - x;
      }
    } else {
      if (side === 'left') {
        return x;
      } else {
        return 100 - x;
      }
    }
  }

  shotYPos(team: string, y: number, side: string) {
    if (team === 'HomeTeam') {
      if (side === 'right') {
        return 100 - (100 - y);
      } else {
        return 100 - y;
      }
    } else {
      if (side === 'left') {
        return y;
      } else {
        return 100 - y;
      }
    }
  }

  gradientMap(mapper) {
    this.ctx = (<HTMLCanvasElement>this.heatMap.nativeElement).getContext('2d');

    const imageData = this.ctx.getImageData(0, 0, this.ctxWidth, this.ctxHeight);
    const pixels = imageData.data;
    const numPixels = pixels.length / 4;

    for (let i = 0; i < numPixels; i++) {
      const sample = mapper(pixels[i * 4] / 255 * 100);
      pixels[i * 4 + 0] = sample[0];
      pixels[i * 4 + 1] = sample[1];
      pixels[i * 4 + 2] = sample[2];
    }
    this.ctx.putImageData(imageData, 0, 0);
  }

  createColorPaletteWithColors(colorStops) {
    const canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 1;
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 100, 0);

    colorStops.forEach(stop => {
      const [offset, color] = stop;
      gradient.addColorStop(offset, color);
    });

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 100, 1);

    const memoizeMap = new Map();
    return (pos) => {
      pos = Math.ceil(pos) - 1;
      if (memoizeMap.has(pos)) {
        return memoizeMap.get(pos);
      }
      const [r, g, b] = Array.from(ctx.getImageData(pos, 0, 1, 1).data);
      const result = [r, g, b];
      memoizeMap.set(pos, result);
      return result;
    };
  }

  brush(color, weight) {
    const brushSize = this.ctxWidth / 5.7;
    const smallR = this.ctxWidth / 153;
    const bigR = this.ctxWidth / 14.34;

    const canvas = document.createElement("canvas");
    canvas.width = brushSize;
    canvas.height = brushSize;
    const ctx = canvas.getContext("2d");

    const grd = ctx.createRadialGradient((brushSize / 2), (brushSize / 2), smallR, (brushSize / 2), (brushSize / 2), bigR);
    grd.addColorStop(0, `rgba(${color},${color},${color},${weight})`);
    grd.addColorStop(.1, `rgba(${color},${color},${color},${weight * .8})`);
    grd.addColorStop(.3, `rgba(${color},${color},${color},${weight * .6})`);
    grd.addColorStop(.6, `rgba(${color},${color},${color},${weight * .2})`);
    grd.addColorStop(.8, `rgba(${color},${color},${color},${weight * .1})`);
    grd.addColorStop(1, `rgba(${color},${color},${color},${weight * 0})`);
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    return canvas;
  }

  drawCourt() {
    this.ctx = (<HTMLCanvasElement>this.heatMap.nativeElement).getContext('2d');
    const canvas = document.createElement("canvas");
    canvas.width = this.ctxWidth;
    canvas.height = this.ctxHeight;
    const ctx = canvas.getContext("2d");

    const strokeColor = "#000";

    // 96
    const halfCircleR = ctx.canvas.width / 11.95;
    // 172
    const paintWidth = ctx.canvas.width / 6.67;
    // 262
    const paintHeight = ctx.canvas.width / 4.377;
    // 100
    const hundred = ctx.canvas.width / 11.47;
    // 276
    const threeR = ctx.canvas.width / 4.156;
    // 90
    const threeStart = ctx.canvas.width / 12.744;
    // 74
    const threeStart2 = ctx.canvas.width / 15.5;

    ctx.beginPath();
    ctx.lineWidth = ctx.canvas.width / 95.58;
    ctx.strokeStyle = strokeColor;

    // create rect for court and draw half court line
    ctx.strokeRect(0, 0, canvas.width, canvas.height) ;
    ctx.lineWidth = ctx.canvas.width / (95.58 * 2);
    ctx.moveTo((canvas.width / 2), 0);
    ctx.lineTo((canvas.width / 2), canvas.height) ;
    ctx.moveTo((canvas.width / 2), ((canvas.height / 2) - halfCircleR));
    ctx.arc((canvas.width / 2), (canvas.height / 2), halfCircleR, 1.5 * Math.PI, Math.PI * 4);

    // left 3pt
    ctx.moveTo(0, threeStart2);
    ctx.arc(threeStart, (canvas.height / 2), threeR, 1.5 * Math.PI, Math.PI / 2, false);
    ctx.moveTo(0, (canvas.height  - (threeStart2 + 1)));
    ctx.lineTo(threeStart, (canvas.height  - (threeStart2 + 1)));

    // right 3pt
    ctx.moveTo(canvas.width ,  threeStart2);
    ctx.arc((canvas.width - threeStart), (canvas.height / 2), threeR, 1.5 * Math.PI, Math.PI / 2, true);
    ctx.moveTo(canvas.width,  (canvas.height - (threeStart2 + 1)));
    ctx.lineTo((canvas.width - threeStart), (canvas.height - (threeStart2 + 1)));

    ctx.moveTo(paintWidth, (canvas.height / 2) + (paintWidth / 2));

    // create left court
    ctx.fillStyle = strokeColor;
    ctx.strokeRect(0, ((canvas.height / 2) - (paintWidth / 2)), paintHeight, paintWidth);
    ctx.fillRect(hundred, ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .14), hundred * .2);
    ctx.fillRect((hundred * 1.5), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect((hundred * 1.9), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect((hundred * 2.35), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect(hundred, ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .14), hundred * .2);
    ctx.fillRect((hundred * 1.5), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.fillRect((hundred * 1.9), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.fillRect((hundred * 2.35), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.arc(paintHeight, (canvas.height / 2), (paintWidth / 2), Math.PI / 2, 1.5 * Math.PI, true);

    ctx.moveTo(canvas.width - paintWidth, (canvas.height / 2) + (paintWidth / 2));

    // create right court
    ctx.strokeRect((canvas.width - paintHeight), ((canvas.height / 2) - (paintWidth / 2)), paintHeight, paintWidth);
    ctx.fillRect((canvas.width - hundred - (hundred * .14)), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .14), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 1.5) - (hundred * .06)), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 1.9) - (hundred * .06)), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 2.35) - (hundred * .06)), ((canvas.height / 2) - (paintWidth / 2) - hundred * .2), (hundred * .06), hundred * .2);
    ctx.fillRect((canvas.width - hundred - (hundred * .14)), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .14), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 1.5) - (hundred * .06)), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 1.9) - (hundred * .06)), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.fillRect((canvas.width - (hundred * 2.35) - (hundred * .06)), ((canvas.height / 2) + (paintWidth / 2) + hundred * .03), (hundred * .06), hundred * .2);
    ctx.arc((canvas.width - paintHeight), (canvas.height / 2), paintWidth / 2, Math.PI / 2, 1.5 * Math.PI, false);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineWidth = hundred * .04;
    // left rim
    ctx.moveTo((hundred * .53), ((canvas.height / 2) + (hundred / 2)));
    ctx.lineTo((hundred * .53), ((canvas.height / 2))  - (hundred / 2));
    ctx.moveTo((hundred * .53), ((canvas.height / 2)));
    ctx.lineTo((hundred * .64), ((canvas.height / 2)));

    ctx.arc((hundred * .85), (canvas.height / 2), hundred * .2, Math.PI, 4 * Math.PI, false);
    ctx.moveTo((hundred * .7), ((canvas.height / 2) - (hundred * .53)));
    ctx.arc((hundred * .85), (canvas.height / 2), (hundred * .53), 1.5 * Math.PI, Math.PI / 2, false);
    ctx.lineTo((hundred * .7), ((canvas.height / 2) + (hundred * .53)));

    // right rim
    ctx.moveTo((canvas.width - (hundred * .53)), ((canvas.height / 2) + (hundred / 2)));
    ctx.lineTo((canvas.width - (hundred * .53)), ((canvas.height / 2))  - (hundred / 2));
    ctx.moveTo((canvas.width - (hundred * .53)), ((canvas.height / 2)));
    ctx.lineTo((canvas.width - (hundred * .64)), ((canvas.height / 2)));

    ctx.arc((canvas.width - (hundred * .85)), (canvas.height / 2), hundred * .2, 0, 2 * Math.PI, false);
    ctx.moveTo((canvas.width - hundred * .7), ((canvas.height / 2) - (hundred * .53)));
    ctx.arc((canvas.width - (hundred * .85)), (canvas.height / 2), (hundred * .53), 1.5 * Math.PI, Math.PI / 2, true);
    ctx.lineTo((canvas.width - hundred * .7), ((canvas.height / 2) + (hundred * .53)));

    ctx.stroke();

    this.ctx.drawImage(canvas, 0, 0);
  }

  draw() {
    this.ctx = (<HTMLCanvasElement>this.heatMap.nativeElement).getContext('2d');

    const light = this.brush(255, 0.4);

    this.canvasEl.width = this.ctxWidth;
    this.canvasEl.height = this.ctxHeight;

    const r = this.ctxWidth / (5.7 * 2);

    this.ctx.clearRect(0, 0, this.ctxWidth, this.ctxHeight);
    this.ctx.fillStyle = "rgba(0, 0, 0, .8)";
    this.ctx.fillRect(0, 0, this.ctxWidth, this.ctxHeight);

    const sortedPlays = this.filteredPlays.sort((a, b) => a.action === b.action ? 0 : a.action === "GOOD" ? 1 : -1);

    this.drawCourt();
    for (const play of sortedPlays) {
      const x = this.shotXPos(play.team, play.coordinate.x, play.coordinate.side);
      const y = this.shotYPos(play.team, play.coordinate.y, play.coordinate.side);

      this.ctx.drawImage(light, ((x / 100) * (this.ctxWidth) - r), ((y / 100) * (this.ctxHeight) - r));
    }

    this.gradientMap(this.createColorPaletteWithColors([
      [0.0, "#000"],
      [0.2, "#0033FF"],
      [0.4, "#00BFFF"],
      [0.6, "#00FF59"],
      [0.8, "#FFEE00"],
      [1.0, "#F22800"]
    ]));

  }

  showPlayerStats(player: LiveStatsPlayer, team: LiveStatsGameTeam) {
    this.modalService.openDialog(this.viewRef, {
      title: `${team.name} - ${player.firstName} ${player.lastName} Stats`,
      childComponent: GamePlayerModalComponent,
      data: player
    });
  }

  ngOnInit() {
    this.canvasEl = this.heatMap.nativeElement;
    this.ctxParent = this.heatMap.nativeElement.parentNode;
    this.ctxWidth = this.ctxParent.clientWidth;
    this.ctxHeight = this.ctxParent.clientHeight;
    this.draw();
  }

  ngOnChanges() {
    this.canvasEl = this.heatMap.nativeElement;
    this.ctxParent = this.heatMap.nativeElement.parentNode;
    this.ctxWidth = this.ctxParent.clientWidth;
    this.ctxHeight = this.ctxParent.clientHeight;
    this.draw();
  }

  ngOnDestroy() {
    if (this.drawSubscription) {
      this.drawSubscription.unsubscribe();
    }
  }
}
