import { Component, OnInit, Input, Inject } from "@angular/core";
import { LiveHubGameViewModel } from "../live-hub.service";
import { ENV_HREF } from "../app.component";

@Component({
  selector: "app-live-hub-games",
  templateUrl: "./live-hub-games.component.html",
  styleUrls: ["./live-hub-games.component.scss"]
})
export class LiveHubGamesComponent implements OnInit {
  @Input() game: LiveHubGameViewModel;
  expanded = false;

  constructor(@Inject(ENV_HREF) public envHref: string) {}

  toggleMedia() {
    event.preventDefault();
    this.expanded = !this.expanded;
  }

  openLink(event: Event, url: string): void {
    event.stopPropagation();
    event.preventDefault();
    window.open(url, "_blank");
  }

  ngOnInit() {}
}
