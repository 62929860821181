import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  HostListener,
  Inject,
} from "@angular/core";
import {
  LiveStatsGameWrapper,
  ArchivedGameReference,
} from "../live-stats-service.service";
import { LiveStatsSettings, ENV_HREF, LIVE_GAME_TOKEN } from "../app.component";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-settings-menu",
  templateUrl: "./settings-menu.component.html",
  styleUrls: ["./settings-menu.component.scss"],
})
export class SettingsMenuComponent implements OnChanges, OnInit {
  @Input() game: LiveStatsGameWrapper;
  @Input() settings: LiveStatsSettings;
  @Input() archivedGames$: Observable<ArchivedGameReference[]>;

  isSettingsActive = false;
  isArchivesActive = false;
  currentUrl = this.router.url;

  windowLocation = window.location.origin;
  liveStatsPath = this.currentUrl.split("/");
  school = this.liveStatsPath[1].toLowerCase();
  sportPath = this.liveStatsPath[2].toLowerCase();
  sport = this.sportPath.split(";")[0].toLowerCase();

  hasCoordinates = false;

  constructor(
    private router: Router,
    @Inject(ENV_HREF) public envHref: string,
    @Inject(LIVE_GAME_TOKEN) public game$: Observable<LiveStatsGameWrapper>
  ) {
    this.game$.subscribe((game) => {
      for (let i = 0, j = game.plays.length; i < j; i++) {
        if (game.plays[i].coordinate !== null) {
          this.hasCoordinates = true;
        }
      }
    });
  }

  toggleSettings() {
    this.isSettingsActive = !this.isSettingsActive;
    if (window.innerWidth < 1024) {
      document
        .getElementsByTagName("body")[0]
        .classList.toggle("noScroll", this.isSettingsActive);
    }
  }

  toggleArchives() {
    this.isArchivesActive = !this.isArchivesActive;
    if (window.innerWidth < 1024) {
      document
        .getElementsByClassName("c-nav-mobile__sponsor")[0]
        .classList.toggle("noShow", this.isArchivesActive);
    }
  }

  clickOutsideSettings(e: Event) {
    if (this.isSettingsActive === true) {
      this.isSettingsActive = false;
      this.isArchivesActive = false;
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      if (this.isSettingsActive === true) {
        this.isSettingsActive = false;
        this.isArchivesActive = false;
      }
    }
  }

  setTheme(value) {
    this.settings.selectedTheme = value;
  }

  setFontSize(value) {
    this.settings.fontSize = value;
  }

  switchToGame(game: ArchivedGameReference, ev: MouseEvent) {
    ev.preventDefault();
    this.router.navigate([
      game.school,
      game.sport,
      { game: game.gameId },
      "summary",
    ]);
    this.isArchivesActive = false;
    this.isSettingsActive = false;
    document.getElementsByTagName("body")[0].classList.add("archivedGame");
    document
      .getElementsByTagName("body")[0]
      .classList.toggle("noScroll", this.isSettingsActive);
  }

  ngOnInit() {
    if (this.currentUrl.indexOf("game=") > -1) {
      document.getElementsByTagName("body")[0].classList.add("archivedGame");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("archivedGame");
    }
  }

  ngOnChanges(changes: SimpleChanges) {}
}
