import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SummaryComponent } from "../pages/summary/summary.component";
import { IndividualComponent } from "../pages/individual/individual.component";
import { TeamStatsComponent } from "../pages/team-stats/team-stats.component";
import { LeadersComponent } from "../pages/leaders/leaders.component";
import { PlayByPlayComponent } from "../pages/play-by-play/play-by-play.component";
import { DriveSummariesComponent } from "../pages/drive-summaries/drive-summaries.component";
import { ShotChartComponent } from "../pages/shot-chart/shot-chart.component";
import { MediaBoxComponent } from "../pages/media-box/media-box.component";
import { PrintStatsComponent } from "../pages/print-stats/print-stats.component";
import { LiveHubComponent } from "../pages/live-hub/live-hub.component";

const appRoutes: Routes = [
  {
    path: ":school/:sport",
    children: [
      {
        path: "",
        children: [
          { path: "", redirectTo: "summary", pathMatch: "full" },
          {
            path: "summary",
            component: SummaryComponent,
            data: { state: "summary" }
          },
          {
            path: "individual",
            component: IndividualComponent,
            data: { state: "individual" }
          },
          {
            path: "team-stats",
            component: TeamStatsComponent,
            data: { state: "team-stats" }
          },
          {
            path: "leaders",
            component: LeadersComponent,
            data: { state: "leaders" }
          },
          {
            path: "play-by-play",
            component: PlayByPlayComponent,
            data: { state: "play-by-play" }
          },
          {
            path: "drive-summaries",
            component: DriveSummariesComponent,
            data: { state: "drive-summaries" }
          },
          {
            path: "shot-chart",
            component: ShotChartComponent,
            data: { state: "shot-chart" }
          },
          {
            path: "media",
            component: MediaBoxComponent,
            data: { state: "media" }
          },
          {
            path: "print-stats",
            component: PrintStatsComponent,
            data: { state: "print-stats" }
          }
        ]
      }
    ],
    runGuardsAndResolvers: "always"
  },
  { path: "hub", component: LiveHubComponent },
  {
    path: "**",
    component: SummaryComponent,
    resolve: {
      url: "externalUrlRedirectResolver"
    },
    data: {
      externalUrl: (<any>window).default_redirect || "/hub"
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { onSameUrlNavigation: "reload", enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class RoutingModule { }

export const AppRouting = RouterModule.forRoot(appRoutes, {
  useHash: true
});
